import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import { Provider } from 'react-redux';
import configureStore from './store'

async function init() {
  // Delay render until store is configured and rehydrated.
  const store = await configureStore();
  ReactDOM.render(<Provider store={ store }><App /></Provider>, document.getElementById('root'));
}

init()

registerServiceWorker();