import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSadTear } from '@fortawesome/free-regular-svg-icons';

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderBy: '',
      orderAsc: true
    };
    this.order = this.order.bind(this);
  }

  order(field) {
    if (!field) return;

    const { orderBy, orderAsc } = this.state;
    const nextOrderAsc = field === orderBy ? !orderAsc : true;

    this.setState({
      orderBy: field,
      orderAsc: nextOrderAsc
    });

    this.props.order(field, nextOrderAsc);
  }

  render() {
    const { columns, data, page, pageSize } = this.props;
    const { orderBy, orderAsc } = this.state;

    return (
      <div className="">
        <table className="table table--hover-highlight mb-0">
          <thead>
          <tr>
            {columns.map((column, idx) => (
              <th
                key={idx}
                width={column.width}
                onClick={() => this.order(column.orderField)}
                className={column.orderField ? `table--order ${
                  column.orderField === orderBy ? 'table--order-active'
                    + (orderAsc ? ' table--order-asc' : ' table--order-desc') : ''
                }` : ''}
              >
                {column.Header}
              </th>
            ))}
          </tr>
          </thead>
          <tbody>
          {data.map((row, rowIdx) => (
            <tr key={rowIdx}>
              {columns.map((column, colIdx) => (
                <td key={colIdx} className={column.className + ' ' + (column.truncate ? 'td--truncate' : '')}>
                  {column.valueType === 'index'
                    ? <span className="text-muted">{(rowIdx + 1) + (pageSize * (page - 1))}</span>
                    : column.Cell
                      ? column.Cell(row)
                      : null
                  }
                </td>
              ))}
            </tr>
          ))}
          {data.length === 0 ? (
            <tr>
              <td colSpan={columns.length} className="text-center py-4 text-muted">
                <FontAwesomeIcon icon={faSadTear} size="5x" className="text-dim mb-2"/>
                <div>Oops, There&apos;s nothing here.</div>
              </td>
            </tr>
          ) : null}
          </tbody>
        </table>
      </div>
    );
  }
}

Table.protoTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      // id: PropTypes.string,
      Header: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.func
      ]),
      accessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      Cell: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.func
      ]),
      truncate: PropTypes.bool,
      width: PropTypes.string,
      className: PropTypes.string
    })
  )
};

Table.defaultProps = {
  columns: {
    className: '',
    truncate: false
  }
};

export default Table;
