import React, { Component, Fragment } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';

import SideNav from '../../components/SideNav';
import TopNav from '../../components/TopNav';
import {
  getContactList,
  getContactData,
  getContactLoading,
  getContactPagination
} from '../../selectors/contactSelectors';
import { pageUpdate } from '../../actions/settingsActions';
import { contactRequest } from '../../actions/contactActions';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import RefreshButton from '../../components/RefreshButton';
import SearchBox from '../../components/SearchBox';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderBy: '',
      orderAsc: false,
      search: '',
      page: 1
    };
    this.fetchContact = this.fetchContact.bind(this);
    this.order = this.order.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchContactDebounced = debounce(this.fetchContact, 500);
  }

  componentDidMount() {
    const { pageUpdateConnect } = this.props;
    pageUpdateConnect('Contact');
    this.fetchContact();
  }

  handleChange(event) {
    const {
      target: { name, type, value, checked }
    } = event;

    const fieldValue = type === 'checkbox' ? checked : value;

    this.setState({ [name]: fieldValue }, () => {
      if (name === 'search') {
        this.fetchContactDebounced();
      }
    });
  }

  fetchContact(page = 1) {
    const { pagination, contactRequestConnect } = this.props;

    const { search, orderBy, orderAsc } = this.state;

    const params = {
      search,
      pageSize: pagination.pageSize,
      page,
      orderBy,
      orderDir: orderAsc ? 'ASC' : 'DESC'
    };

    contactRequestConnect(params);
  }

  order(orderBy, orderAsc = true) {
    this.setState(
      {
        orderBy,
        orderAsc
      },
      this.fetchContact
    );
  }

  render() {
    const {
      // authenticated,
      isLoading,
      contactList,
      contactData,
      pagination
    } = this.props;

    const { search } = this.state;

    const columns = [{
      Header: '',
      valueType: 'index'
    },
      {
        Header: 'Email/Phone',
        accessor: '',
        Cell: props => (
          <Fragment>
            <Link to={`/contact/${props.id}`} className="font-weight-bold">
              {props.email}
            </Link>
            <span className="text-muted small d-block">{props.phone}</span>
          </Fragment>
        ),
        orderField: 'email'
      },
      {
        Header: 'Message',
        width: '50%',
        Cell: props => props.description,
        truncate: true
      },
      {
        Header: 'Received On',
        Cell: props => (
          <span className="text-muted">
            {moment
              .unix(props.created)
              .calendar(null, { sameElse: 'MMMM D, YYYY' })}
          </span>
        ),
        orderField: 'created'
      }
    ];

    const data = contactList.map(id => contactData[id]);

    return (
      <div className="page-contact">
        <SideNav/>
        <TopNav/>
        <div className="content">
          <div className="page-nav d-flex align-items-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <SearchBox
                    onChange={this.handleChange}
                    value={search}
                    name="search"
                    placeholder="Search Contact Messages"
                  />
                </div>
                <div className="col text-right">
                  <Pagination
                    page={pagination.page}
                    pageSize={pagination.pageSize}
                    total={pagination.total}
                    onPageChange={this.fetchContact}
                  />
                  <RefreshButton
                    isRefreshing={isLoading}
                    className="ml-4"
                    onClick={() => {
                      this.fetchContact(pagination.page);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="content-inner">
            <div className="row">
              <div className="col">
                <div
                  className={`card card--dash ${
                    isLoading ? 'card--loading' : null
                  }`}
                >
                  <Table columns={columns} data={data} order={this.order} page={this.state.page}
                         pageSize={this.props.pagination.pageSize}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Contact.propTypes = {};

Contact.defaultProps = {
  isLoading: false
};

const mapStateToProps = state => ({
  pagination: getContactPagination(state),
  isLoading: getContactLoading(state),
  contactList: getContactList(state),
  contactData: getContactData(state)
});

const mapDispatchToProps = dispatch => ({
  contactRequestConnect: params => {
    dispatch(contactRequest(params));
  },
  pageUpdateConnect: params => {
    dispatch(pageUpdate(params));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact);
