import { put, takeEvery, all } from 'redux-saga/effects';

import careersApi from '../api/careersApi';
import {
  CAREERS_REQUEST,
  careersSuccess,
  careersFailure
} from '../actions/careersActions';
import { apiWithAuth } from './requestSagas';
import { pageUpdate } from '../actions/settingsActions';

function* careersRequest(action) {
  try {
    const result = yield apiWithAuth(careersApi.getCareers, action);
    yield put(careersSuccess(result.data));
    yield put(pageUpdate(`Careers`));
  } catch (error) {
    yield put(careersFailure(error));
  }
  // return;
  // try {
  //   const accessToken = yield select(getAccessToken);
  //   // yield put({type: LOGIN_REQUEST});
  //   // yield delay(1000);
  //   const result = yield call(applicationApi.getApplications, action.payload, {
  //     'Authorization': `Bearer ${accessToken}`
  //   });
  //   yield put({type: APPLICATION_SUCCESS, payload: result.data, params: action.payload});
  //   if (result.career) {
  //     yield put({type: CAREERS_SINGLE_SUCCESS, payload: result.career});
  //   }
  // } catch (error) {
  //   yield put({type: APPLICATION_FAILURE, payload: error});
  // }
}

export default function* rootSaga() {
  yield all([takeEvery(CAREERS_REQUEST, careersRequest)]);
}
