import { put, takeEvery, all } from 'redux-saga/effects';
import dashboardApi from '../api/dashboardApi';

import {
  DASHBOARD_REQUEST,
  dashboardSuccess,
  dashboardFailure,
  graphSuccess,
  graphFailure
} from '../actions/dashboardActions';

import { apiWithAuth } from './requestSagas';

function* dashboardRequest(action) {
  try {
    // eslint-disable-next-line no-param-reassign
    action.payload = {};
    const result = yield apiWithAuth(dashboardApi.getDashboardCards, action);
    yield put(dashboardSuccess(result.data, action.payload));
    // if (result.data.career) {
    //   yield put(careersSingleSuccess(result.data.career));
    //   yield put(pageUpdate(`Application: ${result.data.career.title}`));
    // } else {
    //   yield put(pageUpdate(`Application`));
    // }
  } catch (error) {
    // yield put(pageUpdate(`Careers`));
    yield put(dashboardFailure(error));
  }

  try {
    const graph = yield apiWithAuth(dashboardApi.getDashboardGraph, action);
    yield put(graphSuccess(graph.data, action.payload));
  } catch (error) {
    yield put(graphFailure(error));
  }
  // return;
  // try {
  //   const accessToken = yield select(getAccessToken);
  //   // yield put({type: LOGIN_REQUEST});
  //   // yield delay(1000);
  //   const result = yield call(applicationApi.getApplications, action.payload, {
  //     'Authorization': `Bearer ${accessToken}`
  //   });
  //   yield put({type: APPLICATION_SUCCESS, payload: result.data, params: action.payload});
  //   if (result.career) {
  //     yield put({type: CAREERS_SINGLE_SUCCESS, payload: result.career});
  //   }
  // } catch (error) {
  //   yield put({type: APPLICATION_FAILURE, payload: error});
  // }
}

export default function* rootSaga() {
  yield all([takeEvery(DASHBOARD_REQUEST, dashboardRequest)]);
}
