import React, { Component } from 'react';
import { connect } from 'react-redux'

import { isAuthenticated } from '../../selectors/authSelectors'
import { Redirect, Link } from 'react-router-dom';
import SideNav from '../../components/SideNav';
import TopNav from '../../components/TopNav';
import { pageUpdate } from '../../actions/settingsActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withAuth } from '../../services/authServices';

import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';

import CareersForm from './CareersForm';
import { getCareer } from '../../selectors/careersSelectors';

import Axios from 'axios';
import { careersSingleSuccess, careersSingleFailure } from '../../actions/careersActions';

const API = Axios.create({ baseURL: process.env.REACT_APP_APIURL })


class CareersNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageTitle: "Careers",
      loading: false,
      loaded: false
    }

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true
    });
    API.get(`careers/${this.props.match.params.id}/`, {
      headers: withAuth({ 'Content-Type': 'application/json' }),
    })
    .then((res)=> { 
      if (res.data) {
        this.props.careersSingleSuccess(res.data);
        this.props.pageUpdate(res.data.title);
      }
      this.setState({
        loading: false,
        loaded: true
      });

    })
    .catch((error)=> { 
      this.setState({
        error: true,
        loading: false
      })
    });

  }

  onSubmit() {
    this.props.history.push('/career');
  }


  render() {
    console.log(this.props);
    if(!this.props.isAuthenticated) {
      return (
        <Redirect to='/login' />
      )
    } else {
      return <div className="page-careers">
        <SideNav/>
        <TopNav/>
        <div className="content">
          <div className="page-nav d-flex align-items-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col text-left">
                  <Link to="/career" className="btn btn-light">
                    <FontAwesomeIcon icon={faArrowLeft} transform="shrink-5"/> Back to Careers
                  </Link>
                </div>
                <div className="col text-right">
                  <Link to="/career/new" className="btn btn-primary">
                    <FontAwesomeIcon icon={faPlus} transform="shrink-5"/> New Career
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="content-inner">
            <div className="row">
              <div className="col">
                <div className="card card--dash">


                  <div className="card-body">

                    {this.state.loaded && <CareersForm onSubmit={this.onSubmit} career={this.props.career(this.props.match.params.id)}/>}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: isAuthenticated(state),
  career: getCareer(state),
})

const mapDispatchToProps = (dispatch) => ({
  pageUpdate: (page) => {
    dispatch(pageUpdate(page))
  },
  careersSingleSuccess: (payload) => {
    dispatch(careersSingleSuccess(payload))
  },
  careersSingleFailure: () => {
    dispatch(careersSingleFailure());
  }

})

export default connect(mapStateToProps, mapDispatchToProps)(CareersNew);