import * as careers from '../actions/careersActions'

const initialState = {
  careersRequest: false,
  careersData: {},
  careersList: [],
  careersErrors: {},
  careersSingleErrors: {},
  careersNewRequest: false
}

export default (state=initialState, action) => {
  switch(action.type) {
    case careers.CAREERS_REQUEST:
      return {
        ...state,
        careersRequest: true
      }

    case careers.CAREERS_SUCCESS:
      const careersList = [];
      const careersData = {};
      action.payload.forEach((career => {
        careersList.push(career.id);
        careersData[career.id] = career
      }));
      return {
        ...state,
        careersData: {
          ...state.careersData,
          ...careersData
          // [action.payload.id]: action.payload
        },
        // careersList: [...state.careersList, action.payload.id]
        careersList: careersList
      }
    case careers.CAREERS_FAILURE:
      return {
        ...state,
        errors: 
        action.payload.data || 
           {'non_field_errors': action.payload.statusText},
      }
    case careers.CAREERS_DELETE:
      return {
        ...state,
        careersList: state.careersList.filter(id => {
          return id !== action.id
        })
      }
    case careers.CAREERS_SINGLE_SUCCESS:
      return {
        ...state,
        careersData: {
          ...state.careersData,
          [action.payload.id]: action.payload
        }
      }
    case careers.CAREERS_SINGLE_FAILURE:
      return {
        ...state,
        careersSingleErrors:{
          error:"error" // TODO
        }
      }
    default:
      return state
  }
}

export function getCareer(state) {
  // return state.careersData[career_id];
  return function(career_id) {console.log(career_id); return state.careersData[career_id];}
}

export function getCareersList(state) {
  return state.careersList;
}

export function getCareersData(state) {
  return state.careersData;
}
