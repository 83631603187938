import { put, takeEvery, all } from 'redux-saga/effects';

import contactApi from '../api/contactApi';
import {
  CONTACT_REQUEST,
  contactSuccess,
  contactFailure,
  CONTACT_SINGLE_REQUEST,
  contactSingleSuccess,
  contactSingleFailure
} from '../actions/contactActions';
import { apiWithAuth } from './requestSagas';
import { pageUpdate } from '../actions/settingsActions';

function* contactRequest(action) {
  try {
    const result = yield apiWithAuth(contactApi.getContacts, action);
    yield put(contactSuccess(result.data, action.payload));
    yield put(pageUpdate(`Contact`));
  } catch (error) {
    yield put(contactFailure(error));
    yield put(pageUpdate(`Contact`));
  }
}

function* contactSingleRequest(action) {
  try {
    const result = yield apiWithAuth(contactApi.getContact, action);
    yield put(contactSingleSuccess(result.data, action.payload));
    yield put(pageUpdate(`Contact`));
  } catch (error) {
    yield put(contactSingleFailure(error));
    yield put(pageUpdate(`Contact`));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(CONTACT_REQUEST, contactRequest),
    takeEvery(CONTACT_SINGLE_REQUEST, contactSingleRequest)
  ]);
}
