import {
  ACADEMY_APPLICATION_LIST_REQUEST,
  ACADEMY_APPLICATION_LIST_SUCCESS,
  ACADEMY_APPLICATION_LIST_FAILURE,
  ACADEMY_APPLICATION_REQUEST,
  ACADEMY_APPLICATION_SUCCESS,
  ACADEMY_APPLICATION_FAILURE
} from '../actions/academyApplicationActions';

const paginationLimit = parseInt(process.env.REACT_APP_PAGINATION_SIZE, 10);

const initialState = {
  loading: false,
  data: {},
  list: [],
  pagination: {
    total: 0,
    pageSize: paginationLimit,
    page: 1
  },
  errors: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACADEMY_APPLICATION_LIST_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case ACADEMY_APPLICATION_LIST_SUCCESS: {
      const list = [];
      const data = {};
      action.payload.data.results.forEach(application => {
        list.push(application.id);
        data[application.id] = application;
      });

      return {
        ...state,
        data: {
          ...state.data,
          ...data
        },
        list,
        pagination: {
          total: action.payload.data.count,
          pageSize: action.params.pageSize
            ? action.params.pageSize
            : paginationLimit,
          page: action.params.page ? action.params.page : 1
        },
        loading: false,
        errors: {}
      };
    }

    case ACADEMY_APPLICATION_LIST_FAILURE: {
      return {
        ...state,
        errors: action.payload.data || {
          non_field_errors: action.payload.statusText
        },
        loading: false
      };
    }

    case ACADEMY_APPLICATION_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case ACADEMY_APPLICATION_SUCCESS: {
      const contact = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [contact.id]: contact
        },
        errors: {},
        loading: false
      };
    }
    case ACADEMY_APPLICATION_FAILURE: {
      return {
        ...state,
        errors: {
          ...action.payload
        },
        loading: false
      };
    }

    default:
      return state;
  }
};

export function getAcademyApplication(state) {
  return contactId => {
    return state.data[contactId];
  };
}

export function getAcademyApplicationList(state) {
  return state.list;
}

export function getAcademyApplicationData(state) {
  return state.data;
}

export function getAcademyApplicationLoading(state) {
  return state.loading;
}

export function getAcademyApplicationPagination(state) {
  return state.pagination;
}
