import React, { Component } from 'react';
import { connect } from 'react-redux'
import { isSideNavCollapsed } from '../selectors/settingsSelectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFileAlt, faBuilding} from '@fortawesome/free-regular-svg-icons';

import logo from '../assets/img/iw-logo-2-white.svg'
import { NavLink } from 'react-router-dom';
import { faPencilAlt, faTachometerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

class SideNav extends Component {
  render() {
    return <div className="side-nav">
      <div className="side-nav__brand d-flex align-items-center">
        <NavLink to="/"><img src={logo} alt="insight workshop"/></NavLink>
      </div>

      <nav className="side-nav__list nav flex-column mt-3">
        <NavLink to="/" className="nav-link" activeClassName="active" exact><span className="side-nav__icon"><FontAwesomeIcon icon={faTachometerAlt}/></span><span className="side-nav__text">Dashboard</span></NavLink>
        <NavLink to="/career" className="nav-link" activeClassName="active"><span className="side-nav__icon"><FontAwesomeIcon icon={faBuilding}/></span><span className="side-nav__text">Career</span></NavLink>
        <NavLink to="/application" className="nav-link" activeClassName="active"><span className="side-nav__icon"><FontAwesomeIcon icon={faFileAlt}/></span><span className="side-nav__text">Application</span></NavLink>
        <NavLink to="/blog" className="nav-link" activeClassName="active"><span className="side-nav__icon"><FontAwesomeIcon icon={faPencilAlt}/></span><span className="side-nav__text">Blog</span></NavLink>
        <NavLink to="/contact" className="nav-link" activeClassName="active"><span className="side-nav__icon"><FontAwesomeIcon icon={faEnvelope}/></span><span className="side-nav__text">Contact</span></NavLink>
        <NavLink to="/academy/applicants" className="nav-link" activeClassName="active"><span className="side-nav__icon"><FontAwesomeIcon icon={faEnvelope}/></span><span className="side-nav__text">Academy</span></NavLink>
      </nav>
    </div>
  }
};



const mapStateToProps = (state) => ({
  sideNavCollapsed: isSideNavCollapsed(state)
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);