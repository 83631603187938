import React, { Component } from 'react';
import { connect } from 'react-redux'

import Axios from 'axios';
import { Formik } from 'formik';
import { withAuth } from '../../services/authServices';

import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-dropdown';

const API = Axios.create({ baseURL: process.env.REACT_APP_APIURL });

const options = [
  { value: 'pending', label: 'Pending' },
  { value: 'shortlisted', label: 'Shortlisted' },
  { value: 'contacted', label: 'Contacted' },
  { value: 'interview-scheduled', label: 'Interview Scheduled' },
  { value: 'interviewed', label: 'Interviewed' },
  { value: 'hired', label: 'Hired' },
  { value: 'waiting', label: 'Waiting' },
  { value: 'rejected', label: 'Rejected' }
];

class ApplicationForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Formik
      validateOnChange={false}
      initialValues={this.props.application}
      onSubmit={(
        values,
        { setSubmitting, setErrors /* setValues and other goodies */ }
      ) => {
        // TODO: check if auth expired. 
        console.log(this.props.application.status, 'plksdfsd')

        // EDIT POST
        const bodyFormData = new FormData();
        bodyFormData.set('notes', values.notes);
        bodyFormData.set('status', this.props.application.status);

        API.patch(`application/applicant/${this.props.application.id}/`, bodyFormData, { headers: withAuth({ 'Content-Type': 'multipart/form-data' }) })
          .then((res) => {
            setSubmitting(false);
            // console.log(this.props);
            this.props.onSubmit();
            // this.props.loginSuccess(res.data);
          })
          .catch((error) => {
            const err = {};
            if (error.response) {
              if (error.response.data) {
                Object.entries(error.response.data).forEach((obj) => {
                  err[obj[0]] = obj[1][0]
                });
                setErrors(err);
              }
              // this.props.loginFailure(error.response);
            } else {
              // this.props.loginFailure({
              //   data: {'non_field_errors': error.message},
              // });
              err['non_field_errors'] = error.message
              setErrors(err);
            }
            setSubmitting(false);
          });
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
          <form onSubmit={handleSubmit}>
            <input
              id="txtId"
              className="form-control"
              type="hidden"
              name="id"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.id}
            />
            <div className="row">
              <div className="col-md-6">
                <dl>
                  <dt>Name</dt>
                  <dd>{this.props.application.name}</dd>
                </dl>
                <dl>
                  <dt>Email</dt>
                  <dd>{this.props.application.email}</dd>
                </dl>
                <dl>
                  <dt>Phone</dt>
                  <dd>{this.props.application.phone}</dd>
                </dl>
                {this.props.application.current_company && (
                  <dl>
                    <dt>Current Company</dt>
                    <dd>{this.props.application.current_company}</dd>
                  </dl>
                )}
                {this.props.application.preferred_language && (
                  <dl>
                    <dt>Preferred language</dt>
                    <dd>{this.props.application.preferred_language}</dd>
                  </dl>
                )}
                <div className="pb-2">
                  {this.props.application.cv && (
                    <a href={this.props.application.cv} target="_blank" className="btn btn-outline-primary" rel="noopener noreferrer">View CV</a>
                  )}
                </div>
                <div className="btn-group pb-2">

                  {this.props.linkedin && (
                    <a href={this.props.application.linkedin} target="_blank" className="btn btn-outline-primary" rel="noopener noreferrer">LinkedIn</a>
                  )}
                  {this.props.github && (
                    <a href={this.props.application.github} target="_blank" className="btn btn-outline-primary" rel="noopener noreferrer">GitHub</a>
                  )}
                  {this.props.application.stackoverflow && (
                    <a href={this.props.application.stackoverflow} target="_blank" className="btn btn-outline-primary" rel="noopener noreferrer">StackOverflow</a>
                  )}
                  {this.props.application.portfolio && (
                    <a href={this.props.application.portfolio} target="_blank" className="btn btn-outline-primary" rel="noopener noreferrer">Portfolio</a>
                  )}
                </div>
                {this.props.application.referrer && (
                  <dl>
                    <dt>Referrer</dt>
                    <dd>{this.props.application.referrer}</dd>
                  </dl>
                )}
                {this.props.application.compensation && (
                  <dl>
                    <dt>Compensation</dt>
                    <dd>{this.props.application.compensation}</dd>
                  </dl>
                )}
                {this.props.application.additional_information && (
                  <dl>
                    <dt>Additional Information</dt>
                    <dd>{this.props.application.additional_information}</dd>
                  </dl>
                )}

                <div className="form-group">
                  <label htmlFor="txtStatus">Status</label>
                  <Dropdown
                    id="txtStatus"
                    name="status"
                    options={options}
                    onChange={val => {
                      this.props.application.status = val.value;
                    }}
                    value={this.props.application.status}
                    placeholder="Select an option"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="txtNotes">Notes</label>
                  <textarea
                    id="txtNotes"
                    rows="5"
                    className="form-control"
                    name="notes"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.notes}
                  />
                </div>
                <div>
                  <button className="btn btn-primary text-uppercase font-weight-bold" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Updating Application" : "Update Application"}
                  </button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="blog-preview">
                  <h3>Notes</h3>
                  <ReactMarkdown source={values.notes} className="markdown markdown--blog"
                    escapeHtml={false}
                    plugins={[
                      [
                        require('remark-shortcodes'),
                        { startBlock: "{{>", endBlock: "}}" }
                      ]
                    ]}
                    // astPlugins={[parseHtml]}
                    // transformImageUri={(uri) => {
                    //   // console.log(this.state.used_medias[a].media_medium);
                    //   return this.state.used_medias[uri].media_medium;
                    // }}
                    renderers={{
                      // image: (a, b) => {
                      //   // console.log(a, b);
                      //   return <div>IMGE>...</div>;
                      // },

                    }} />
                </div>
              </div>
            </div>
          </form>
        )
      }
    />

  }

}

export default connect()(ApplicationForm);