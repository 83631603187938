import * as fromAcademyApplication from '../reducers/academyApplicationReducers';

export const getAcademyApplication = state =>
  fromAcademyApplication.getAcademyApplication(state.academyApplication);

export const getAcademyApplicationList = state =>
  fromAcademyApplication.getAcademyApplicationList(state.academyApplication);

export const getAcademyApplicationData = state =>
  fromAcademyApplication.getAcademyApplicationData(state.academyApplication);

export const getAcademyApplicationLoading = state =>
  fromAcademyApplication.getAcademyApplicationLoading(state.academyApplication);

export const getAcademyApplicationPagination = state =>
  fromAcademyApplication.getAcademyApplicationPagination(
    state.academyApplication
  );
