import * as dashboard from '../actions/dashboardActions';

const initialState = {
  dashboardRequest: false,
  dashboardData: {},
  graphData: {},
  graphErrors: {},
  dashboardErrors: {},
  dashboardSingleErrors: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case dashboard.DASHBOARD_FAILURE:
      return Object.assign({}, state, {
        dashboardErrors: action.payload
      });
    case dashboard.DASHBOARD_SUCCESS:
      return Object.assign({}, state, {
        dashboardData: action.payload
      });

    case dashboard.GRAPH_SUCCESS:
      return Object.assign({}, state, {
        graphData: action.payload
      });

    case dashboard.GRAPH_FAILURE:
      return Object.assign({}, state, {
        graphErrors: action.payload
      });
    default:
      return state;
  }
};

export function getDashboardData(state) {
  return state.dashboardData;
}

export function getGraphData(state) {
  return state.graphData;
}
