/*
 * action types
 */
export const ACADEMY_APPLICATION_LIST_REQUEST = '@@academy/APP_LIST_REQUEST';
export const ACADEMY_APPLICATION_LIST_SUCCESS = '@@academy/APP_LIST_SUCCESS';
export const ACADEMY_APPLICATION_LIST_FAILURE = '@@academy/APP_LIST_FAILURE';

export const ACADEMY_APPLICATION_REQUEST = '@@academy/APP_REQUEST';
export const ACADEMY_APPLICATION_SUCCESS = '@@academy/APP_SUCCESS';
export const ACADEMY_APPLICATION_FAILURE = '@@academy/APP_FAILURE';

export const ACADEMY_APPLICATION_UPDATE_STATUS = '@@academy/APP_UPDATE_REQUEST';


/*
 * action creators
 */
export function academyApplicationListRequest(payload) {
  return { type: ACADEMY_APPLICATION_LIST_REQUEST, payload };
}

export function academyApplicationListSuccess(payload, params) {
  return { type: ACADEMY_APPLICATION_LIST_SUCCESS, payload, params };
}

export function academyApplicationListFailure(payload) {
  return { type: ACADEMY_APPLICATION_LIST_FAILURE, payload };
}

export function academyApplicationRequest(payload) {
  return { type: ACADEMY_APPLICATION_REQUEST, payload };
}

export function academyApplicationSuccess(payload, params) {
  return { type: ACADEMY_APPLICATION_SUCCESS, payload, params };
}

export function academyApplicationFailure(payload) {
  return { type: ACADEMY_APPLICATION_FAILURE, payload };
}

export function academyApplicationUpdateStatus(payload) {
  return { type: ACADEMY_APPLICATION_UPDATE_STATUS, payload };
}