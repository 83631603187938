import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';
import SideNav from '../../components/SideNav';
import TopNav from '../../components/TopNav';
import { pageUpdate } from '../../actions/settingsActions';
import { graphRequest, dashboardRequest } from '../../actions/dashboardActions';

import { getDashboard, getGraphData } from '../../selectors/dashboardSelectors';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: 'Dashboard'
    };
  }

  componentDidMount() {
    const { pageUpdateConnect } = this.props;
    const { pageTitle } = this.state;
    pageUpdateConnect(pageTitle);
    // eslint-disable-next-line react/destructuring-assignment
    this.props.dashboardConnectRequest();
    // eslint-disable-next-line react/destructuring-assignment
    this.props.graphConnectRequest();
  }

  renderGraph = _ => {
    const { graphData } = this.props;
    return (
      <React.Fragment>
        <LineChart
          width={840}
          height={350}
          data={graphData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="Job Applicants"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="Academy Applicants" stroke="#82ca9d" />
          {/* <Line type="monotone" dataKey="INT" stroke="#ef9c21" /> */}
        </LineChart>
      </React.Fragment>
    );
  };

  renderCardCol3 = data => {
    // eslint-disable-next-line react/prop-types
    const { Applicants, Careers, Academy, Blog } = this.props.dashboardData;
    const careersClass = ['text-success', 'text-info', 'text-danger'];

    return (
      <React.Fragment>
        <div className="col-3">
          <div className="card card--dash">
            <div className="card-header border-bottom-0"> Applicants </div>
            <div className="card-body border-left border-warning">
              {Object.keys(Applicants || {}).map((key, index) => (
                <div className="d-flex justify-content-between">
                  <span className="text-muted">{key}</span>
                  <span className="badge badge-info align-self-center">
                    {Applicants[key]}
                  </span>
                </div>
              ))}
              <div className="text-center text-primary mt-2 mb-0">
                <Link to='/application'><small>view details..</small></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="card card--dash">
            <div className="card-header border-bottom-0"> Careers </div>
            <div className="card-body card-body border-left border-warning">
              {Object.keys(Careers || {}).map((key, index) => (
                <div className="d-flex justify-content-between">
                  <span className={careersClass[index]}>{key}</span>
                  <span className="badge badge-info align-self-center">
                    {Careers[key]}
                  </span>
                </div>
              ))}
              <div className="text-center text-primary mt-2 mb-0">
                <Link to='/career'><small>view details..</small></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="card card--dash">
            <div className="card-header border-bottom-0"> Academy </div>
            <div className="card-body card-body border-left border-warning">
              {Object.keys(Academy || {}).map((key, index) => (
                <div className="d-flex justify-content-between">
                  <span className="text-muted">{key}</span>
                  <span className="badge badge-info align-self-center">
                    {Academy[key]}
                  </span>
                </div>
              ))}
              <div className="text-center text-primary mt-2 mb-0">
               <Link to='/academy/applicants'> <small>view details..</small> </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-3">
          <div className="card card--dash">
            <div className="card-header border-bottom-0"> Blog </div>
            <div className="card-body card-body border-left border-warning">
              {Object.keys(Blog || {}).map((key, index) => (
                <div className="d-flex justify-content-between" key={index}>
                  <span className="text-muted">{key}</span>
                  <span className="badge badge-info align-self-center">
                    {Blog[key]}
                  </span>
                </div>
              ))}
              <div className="text-center text-primary mt-2 mb-0">
               <Link to='/blog'><small>view details..</small></Link>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="page-dashboard">
        <SideNav />
        <TopNav />
        <main className="content">
          <div className="content-inner">
            <div className="row">{this.renderCardCol3()}</div>
            <div className="row mt-5">
              <div className="col">
                <div className="card card--dash">
                  <div className="card-header">
                    <b>Application Analytics</b>
                  </div>
                  <div className="card-body text-info bg-dark">
                    {this.renderGraph()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  pageUpdateConnect: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  dashboardData: getDashboard(state),
  graphData: getGraphData(state)
});

const mapDispatchToProps = dispatch => ({
  pageUpdateConnect: page => {
    dispatch(pageUpdate(page));
  },
  dashboardConnectRequest: _ => {
    dispatch(dashboardRequest());
  },
  graphConnectRequest: _ => {
    dispatch(graphRequest());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
