import storage from 'redux-persist/lib/storage';
import { createStore, applyMiddleware, compose } from 'redux';
import { createFilter } from 'redux-persist-transform-filter';
import { persistReducer, persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';

import rootReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();
// const middleware = [
//   sagaMiddleware,
//   // process.env.NODE_ENV === "development" && logger,
// ].filter(Boolean);


const authFilter = createFilter('auth', ['access', 'refresh']);


const reducer = persistReducer(
  {
    key: 'store',
    storage,
    whitelist: ['auth', 'settings'],
    transforms: [authFilter]
  },
  rootReducer
);

// export const store = createStore(
//   reducer,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
// )

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
  )
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);


/**
 * Returns store only after store is rehydrated.
 * https://github.com/rt2zz/redux-persist/issues/126#issuecomment-270120325
 */
export default function configureStore() {

  return new Promise((resolve, reject) => {
    try {
      sagaMiddleware.run(rootSaga);

      persistStore(
        store,
        null,
        () => resolve(store)
      );
    } catch (e) {
      reject(e);
    }
  });
}
