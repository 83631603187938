import React, { Component } from 'react';
import { connect } from 'react-redux'

import Axios from 'axios';
import { Formik } from 'formik';
import { withAuth } from '../../services/authServices';

import Textarea from 'react-textarea-autosize';

const API = Axios.create({ baseURL: process.env.REACT_APP_APIURL });

class ApplicationEmailForm extends Component {
  constructor(props) {
    super(props); 
    this.state = {
    }
  }
  render() {
    return <Formik
      // loginSuccess={this.props.loginSuccess}
      initialValues={{
        id: this.props.application.id,
        subject: "",
        message: "",
      }}
      validate={values => {
        // same as above, but feel free to move this into a class method now.
        let errors = {};
        if (!values.subject) {
          errors.subject = "This field may not be blank."
        } 
        if (!values.message) {
          errors.message = "This field may not be blank."
        } 
        return errors;
      }}
      onSubmit={(
        values,
        { setSubmitting, setErrors /* setValues and other goodies */ }
      ) => {
        
        // TODO: check if auth expired. 
        
        if (this.props.application) {
          // Send email
          API.post(`application/${this.props.application.id}/email/`, values, { headers: withAuth({ 'Content-Type': 'application/json' }) })
          .then((res)=> {
            setSubmitting(false);
            this.props.onSuccess();
            this.props.onClose();
          })
          .catch((error)=> {
            const err= {};
            if (error.response) {
              if (error.response.data) {
                Object.entries(error.response.data).forEach((obj) => {
                  err[obj[0]] = obj[1][0]
                });
                setErrors(err);
              }
              // this.props.loginFailure(error.response);
            } else {
              // this.props.loginFailure({
              //   data: {'non_field_errors': error.message},
              // });
              err['non_field_errors'] = error.message
              setErrors(err);
            }
            setSubmitting(false);
          });              
        }
        

      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <input
              id="txtId"
              className="form-control"
              type="hidden"
              name="id"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.id}
            />


          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="txtSubject">Subject</label>
                <input
                  id="txtSubject"
                  className="form-control"
                  type="text"
                  name="subject"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.subject}
                />
                {touched.subject && errors.subject && <div className="invalid-feedback d-block">{errors.subject}</div>}
              </div>
            </div>
            
            
          </div>

          <div className="row">
            <div className="col">
          
          
              <div className="form-group">
                <label htmlFor="txtMessage">Message</label>
                <Textarea
                  rows="10"
                  id="txtMessage"
                  className="form-control textarea"
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                />
                {touched.message && errors.message && <div className="invalid-feedback d-block">{errors.message}</div>}
              </div>
            </div>
            
          </div>

          <div>
            <button className="btn btn-primary text-uppercase font-weight-bold" type="submit" disabled={isSubmitting}>
              { isSubmitting ? "Sending Email" : "Send Email" }
            </button>
          </div>
        </form>
      )}
    />

  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationEmailForm);