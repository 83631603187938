/*
 * action types
 */

export const BLOG_REQUEST = '@@blog/BLOG_REQUEST';
export const BLOG_SUCCESS = '@@blog/BLOG_SUCCESS';
export const BLOG_FAILURE = '@@blog/BLOG_FAILURE';

export const BLOG_SINGLE_REQUEST = '@@blog/BLOG_SINGLE_REQUEST';
export const BLOG_SINGLE_SUCCESS = '@@blog/BLOG_SINGLE_SUCCESS';
export const BLOG_SINGLE_FAILURE = '@@blog/BLOG_SINGLE_FAILURE';

export const BLOG_NEW_REQUEST = '@@blog/BLOG_NEW_REQUEST';
export const BLOG_NEW_SUCCESS = '@@blog/BLOG_NEW_SUCCESS';
export const BLOG_NEW_FAILURE = '@@blog/BLOG_NEW_FAILURE';

export const BLOG_ADD_MEDIA = '@@blog/BLOG_ADD_MEDIA';

export const BLOG_DELETE = '@@blog/BLOG_DELETE';

/*
 * action creators
 */

export function blogRequest() {
  return { type: BLOG_REQUEST }
}

export function blogSuccess(payload) {
  return { type: BLOG_SUCCESS, payload }
}

export function blogFailure(payload) {
  return { type: BLOG_FAILURE, payload }
}

export function blogSingleRequest() {
  return { type: BLOG_SINGLE_REQUEST }
}

export function blogSingleSuccess(payload) {
  return { type: BLOG_SINGLE_SUCCESS, payload }
}

export function blogSingleFailure(payload) {
  return { type: BLOG_SINGLE_FAILURE, payload }
}

export function blogNewSuccess(payload) {
  return { type: BLOG_NEW_REQUEST, payload }
}

export function blogNewFailure(payload) {
  return { type: BLOG_NEW_FAILURE, payload }
}

export function blogNewRequest() {
  return { type: BLOG_NEW_REQUEST }
}

export function blogAddMedia(payload) {
  return { type: BLOG_ADD_MEDIA, payload }
}

export function blogDelete(id) {
  return {type: BLOG_DELETE, id}
}