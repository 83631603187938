import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isAuthenticated } from '../../selectors/authSelectors';
import { Redirect, Link } from 'react-router-dom';
import SideNav from '../../components/SideNav';
import TopNav from '../../components/TopNav';
import { pageUpdate } from '../../actions/settingsActions';
import Axios from 'axios';
import { withAuth } from '../../services/authServices';
import { academyApplicationSuccess } from '../../actions/academyApplicationActions';
import {
  getAcademyApplicationList,
  getAcademyApplicationData
} from '../../selectors/academyApplicationSelectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { careersSingleSuccess } from '../../actions/careersActions';

const API = Axios.create({ baseURL: process.env.REACT_APP_APIURL });


class AcademyApplicationSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: 'Academy Applicant',
      selectedApplicationId: undefined,
      application_id: props.match.params.application_id
    };

  }

  componentDidMount() {
    const application_id = this.props.match.params.application_id;

    // this.props.pageUpdate(this.state.pageTitle);

    // TODO: move this to api
    API.get(`academy/registration/${application_id}/`, {
      headers: withAuth({ 'Content-Type': 'application/json' })
    })
      .then((res) => {
        if (res.data) {
          this.props.applicationSingleSuccess(res.data);
          this.props.pageUpdate(`Applicant: ${res.data.fullname}`);
        }
      })
      .catch((error) => {
        this.props.applicationFailure(error.response);
      });
  }

  logout() {
    this.props.logout();
  }

  toggleEmailModal(applicationId) {
    let selectedApplicationId = this.state.selectedApplicationId;
    if (applicationId in this.props.applicationData) {
      selectedApplicationId = applicationId;
    } else {
      // selectedApplicationId = undefined;
    }
    this.setState({
      emailModal: !this.state.emailModal,
      selectedApplicationId: selectedApplicationId
    });
  }

  toggleEmailSuccessModal() {
    this.setState({
      emailSuccessModal: !this.state.emailSuccessModal
    });
  }


  render() {
    if (!this.props.isAuthenticated) {
      return (
        <Redirect to='/login'/>
      );
    } else {
      return <div className="page-careers">
        <SideNav/>
        <TopNav/>
        <div className="content">
          <div className="page-nav d-flex align-items-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col text-left">
                  <Link to="/academy/applicants" className="btn btn-light">
                    <FontAwesomeIcon icon={faArrowLeft} transform="shrink-5"/> Back to Academy
                  </Link>
                </div>

                <div className="col text-right">
                  {/* <Link to="/careers/new" className="btn btn-primary">
                    <FontAwesomeIcon icon={faPlus} transform="shrink-5"/> New Career
                  </Link> */}
                </div>
              </div>
            </div>
          </div>

          <div className="content-inner">
            <div className="row">
              <div className="col">
                {this.props.applicationData[this.state.application_id] ?
                  <div className="card card--dash">
                    <div className="card-body">
                      <dl>
                        <dt>Name</dt>
                        <dd>{this.props.applicationData[this.state.application_id].fullname}</dd>
                      </dl>
                      <dl>
                        <dt>Email</dt>
                        <dd>{this.props.applicationData[this.state.application_id].email}</dd>
                      </dl>
                      <dl>
                        <dt>Phone</dt>
                        <dd>{this.props.applicationData[this.state.application_id].phone}</dd>
                      </dl>
                      <dl>
                        <dt>Sessions</dt>
                        <dd>{this.props.applicationData[this.state.application_id].sessions}</dd>
                      </dl>
                      <div className="pb-2">
                        {this.props.applicationData[this.state.application_id].cv && (
                          <a href={this.props.applicationData[this.state.application_id].cv} target="_blank"
                             className="btn btn-outline-primary" rel="noopener noreferrer">View CV</a>
                        )}
                      </div>
                      <div className="btn-group pb-2">

                        {this.props.applicationData[this.state.application_id].linkedin && (
                          <a href={this.props.applicationData[this.state.application_id].linkedin} target="_blank"
                             className="btn btn-outline-primary" rel="noopener noreferrer">LinkedIn</a>
                        )}
                        {this.props.applicationData[this.state.application_id].github && (
                          <a href={this.props.applicationData[this.state.application_id].github} target="_blank"
                             className="btn btn-outline-primary" rel="noopener noreferrer">GitHub</a>
                        )}
                        {this.props.applicationData[this.state.application_id].stackoverflow && (
                          <a href={this.props.applicationData[this.state.application_id].stackoverflow} target="_blank"
                             className="btn btn-outline-primary" rel="noopener noreferrer">StackOverflow</a>
                        )}
                        {this.props.applicationData[this.state.application_id].portfolio && (
                          <a href={this.props.applicationData[this.state.application_id].portfolio} target="_blank"
                             className="btn btn-outline-primary" rel="noopener noreferrer">Portfolio</a>
                        )}
                      </div>
                      {this.props.applicationData[this.state.application_id].referrer && (
                        <dl>
                          <dt>Referrer</dt>
                          <dd>{this.props.applicationData[this.state.application_id].referrer}</dd>
                        </dl>
                      )}
                      {this.props.applicationData[this.state.application_id].collegename && (
                        <dl>
                          <dt>College Name</dt>
                          <dd>{this.props.applicationData[this.state.application_id].collegename}</dd>
                        </dl>
                      )}
                      {this.props.applicationData[this.state.application_id].faculty && (
                        <dl>
                          <dt>Faculty</dt>
                          <dd>{this.props.applicationData[this.state.application_id].faculty}</dd>
                        </dl>
                      )}
                      {this.props.applicationData[this.state.application_id].graduationdate && (
                        <dl>
                          <dt>Graduation Date</dt>
                          <dd>{this.props.applicationData[this.state.application_id].graduationdate}</dd>
                        </dl>
                      )}
                      {this.props.applicationData[this.state.application_id].compensation && (
                        <dl>
                          <dt>Compensation</dt>
                          <dd>{this.props.applicationData[this.state.application_id].compensation}</dd>
                        </dl>
                      )}
                      {this.props.applicationData[this.state.application_id].additional_information && (
                        <dl>
                          <dt>Additional Information</dt>
                          <dd>{this.props.applicationData[this.state.application_id].additional_information}</dd>
                        </dl>
                      )}
                    </div>
                  </div> : 'Loading'
                }

              </div>
            </div>
          </div>
        </div>

      </div>;
    }
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  applicationList: getAcademyApplicationList(state),
  applicationData: getAcademyApplicationData(state)
});

const mapDispatchToProps = (dispatch) => ({
  pageUpdate: (page) => {
    dispatch(pageUpdate(page));
  },
  careersSingleSuccess: (careers) => {
    dispatch(careersSingleSuccess(careers));
  },
  applicationSingleSuccess: (application) => {
    dispatch(academyApplicationSuccess(application));
  },
  applicationFailure: (res) => {
    console.log(res);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AcademyApplicationSingle);
