import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Dropdown from 'react-dropdown';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { pageUpdate } from '../../actions/settingsActions';

import SideNav from '../../components/SideNav';
import TopNav from '../../components/TopNav';
import Table from '../../components/Table';
import Pagination from '../../components/Pagination';
import { withAuth } from '../../services/authServices';
import 'react-dropdown/style.css';

import {
  getAcademyApplicationList,
  getAcademyApplicationData,
  getAcademyApplicationLoading,
  getAcademyApplicationPagination
} from '../../selectors/academyApplicationSelectors';
import {
  academyApplicationListRequest,
  academyApplicationUpdateStatus
} from '../../actions/academyApplicationActions';

const APIURL = process.env.REACT_APP_APIURL;
const API = Axios.create({ baseURL: process.env.REACT_APP_APIURL });

const options = [
  { value: 'pending', label: 'Pending' },
  { value: 'shortlisted', label: 'Shortlisted' },
  { value: 'contacted', label: 'Contacted' },
  { value: 'interview-scheduled', label: 'Interview Scheduled' },
  { value: 'interviewed', label: 'Interviewed' },
  { value: 'hired', label: 'Hired' },
  { value: 'waiting', label: 'Waiting' },
  { value: 'rejected', label: 'Rejected' }
];

class AcademyApplicants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderBy: '',
      orderAsc: false,
      search: '',
      page: 1
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { pageUpdateConnect } = this.props;
    pageUpdateConnect('Academy Applications');
    this.fetchAcademyApplicants();
  }

  // eslint-disable-next-line react/sort-comp
  fetchAcademyApplicants = (page = 1) => {
    this.setState({ page });
    // eslint-disable-next-line react/prop-types
    const { pagination, academyApplicationListRequestConnect } = this.props;
    const { search, orderBy, orderAsc } = this.state;

    const params = {
      search,
      pageSize: pagination.limit,
      page,
      orderBy,
      orderDir: orderAsc ? 'ASC' : 'DESC'
    };
    academyApplicationListRequestConnect(params);
  };

  updateStatus = (id, status) => {
    API.patch(
      `academy/registration/${id}/ `,
      { status: status.value },
      { headers: withAuth({ 'Content-Type': 'application/json' }) }
    )
      .then(res => { })
      .catch(err => { });

    // Todo
    // this.props.updateStatus(id);
  };

  order = (orderBy, orderAsc = true) => {
    this.setState({ orderBy, orderAsc }, () => {
      this.fetchAcademyApplicants();
    });
  };

  render() {
    // eslint-disable-next-line react/prop-types
    const { academyApplicationList, academyApplicationData, academyApplicationPagination } = this.props;
    const columns = [
      {
        Header: '',
        valueType: 'index'
      },
      {
        Header: 'Name',
        accessor: '',
        Cell: application => (
          <Fragment>
            <Link
              to={`/academy/applicant/${application.id}`}
              className="font-weight-bold"
            >
              {application.fullname}
            </Link>
            <span className="text-muted small d-block">
              {application.email}
            </span>
          </Fragment>
        ),
        orderField: 'fullname',
      },
      // {
      //   Header: 'Phone',
      //   Cell: application => (
      //     <span className="text-muted">{application.phone}</span>
      //   ),
      //   orderField: 'phone'
      // },
      {
        Header: 'Sessions',
        Cell: application =>
          application.sessions.map(session => (
            <span className="text-muted small d-block"> {session} </span>
          ))
      },
      {
        Header: 'College Name',
        Cell: application => (
          <span className="text-muted">{application.collegename}</span>
        )
      },
      {
        Header: 'Faculty',
        Cell: application => (
          <span className="text-muted">{application.faculty}</span>
        )
      },
      // {
      //   Header: 'Graduation Date',
      //   Cell: application => (
      //     <span className="text-muted">{
      //       application.graduationdate ?
      //         moment(application.graduationdate)
      //           .calendar(null, { sameElse: 'MMMM D, YYYY' })
      //         : ''
      //     }</span>
      //   )
      // },
      {
        Header: 'Status',
        Cell: application => {
          return (
            <Dropdown
              options={options}
              onChange={val => {
                this.updateStatus(application.id, val);
              }}
              value={application.status}
              placeholder="Select an option"
            />
          );
        }
      },
      {
        Header: 'Applied',
        Cell: application => (
          <span className="text-muted">
            {moment
              .unix(application.created)
              .calendar(null, { sameElse: 'MMMM D, YYYY' })}
          </span>
        ),
        orderField: 'created',
      }
    ];
    const data = academyApplicationList.map(id => academyApplicationData[id]);
    return (
      <div className="page-careers">
        <SideNav/>
        <TopNav/>

        <div className="content">
          <div className="page-nav d-flex align-items-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col-5">
                  <a href={`${APIURL}academy/registration/download/`}>
                    Download CSV of applicaints
                  </a>
                </div>

                <div className="col">
                  <Pagination
                    page={academyApplicationPagination.page}
                    pageSize={academyApplicationPagination.pageSize}
                    total={academyApplicationPagination.total}
                    onPageChange={this.fetchAcademyApplicants}
                  />
                </div>

              </div>
            </div>
          </div>

          <div className="content-inner">
            <div className="row">
              <div className="col">
                <div
                  className="card card--dash"
                  // isLoading ? 'card--loading' : null
                >
                  {/* TODO: show table; */}
                  {<Table columns={columns} data={data} order={this.order} page={this.state.page}
                          pageSize={this.props.pagination.pageSize}/>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AcademyApplicants.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  isAcademyApplicationLoading: PropTypes.bool,
  academyApplicationListRequestConnect: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  academyApplicantsList: PropTypes.arrayOf(PropTypes.number).isRequired,
  academyApplicantsPagination: PropTypes.shape({
    total: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired
  }).isRequired
};

AcademyApplicants.defaultProps = {
  isAcademyApplicationLoading: false
};

const mapStateToProps = state => ({
  pagination: getAcademyApplicationPagination(state),
  isLoading: getAcademyApplicationLoading(state),
  academyApplicationList: getAcademyApplicationList(state),
  academyApplicationData: getAcademyApplicationData(state),
  academyApplicationPagination: getAcademyApplicationPagination(state)
});

const mapDispatchToProps = dispatch => ({
  academyApplicationListRequestConnect: params => {
    dispatch(academyApplicationListRequest(params));
  },
  pageUpdateConnect: page => {
    dispatch(pageUpdate(page));
  },
  updateStatus: params => {
    dispatch(academyApplicationUpdateStatus(params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AcademyApplicants);
