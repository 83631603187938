import { put, all, call, delay, takeLatest } from 'redux-saga/effects';
import AuthApi from '../api/authApi';

import {
  LOGIN_REQUEST,
  loginFailure,
  loginSuccess,
  TOKEN_REQUEST,
  refreshAccessTokenSuccess,
  resumeAuthRequest
} from '../actions/authActions';

function* loginRequest(action) {
  const { payload, formikActions } = action;
  const { setErrors, setSubmitting } = formikActions;
  try {
    yield delay(1000);
    const result = yield call(AuthApi.getAccessToken, payload);
    yield call(setSubmitting, false);
    yield put(loginSuccess(result.data));
  } catch (error) {
    const err = {};
    if (error.response) {
      if (error.response.data) {
        Object.entries(error.response.data).forEach(obj => {
          // eslint-disable-next-line prefer-destructuring
          err[obj[0]] = obj[1][0];
        });
        yield call(setErrors, err);
      }
      yield put(loginFailure(error.response));
    } else {
      yield put(loginFailure({ data: { non_field_errors: error.message } }));
      err.non_field_errors = error.message;
      yield call(setErrors, err);
    }
    yield call(setSubmitting, false);
  }
}

function* refreshAccessToken(action) {
  try {
    const result = yield call(AuthApi.getRefreshedAccessToken, action.payload);
    if (result.status === 200) {
      yield put(refreshAccessTokenSuccess(result.data));
      yield put(resumeAuthRequest());
    }
  } catch (error) {
    // TODO: handle error.
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(LOGIN_REQUEST, loginRequest),
    takeLatest(TOKEN_REQUEST, refreshAccessToken)
  ]);
}
