import API from './api';

const paginationLimit = parseInt(process.env.REACT_APP_PAGINATION_SIZE, 10);

class ContactApi {
  static getContacts(
    {
      pageSize = paginationLimit,
      orderBy = 'created',
      orderDir = 'DESC',
      search = undefined,
      page = 1
    },
    config
  ) {
    const params = { page_size: pageSize, page, orderBy, orderDir, search };
    return API.GET('contact/', params, config);
  }

  static getContact({ contactId = undefined }, config) {
    const params = {};
    return API.GET(`contact/${contactId}/`, params, config);
  }
}

export default ContactApi;
