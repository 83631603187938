import React, { Component } from 'react';
import { connect } from 'react-redux'

import { isAuthenticated } from '../../selectors/authSelectors'
import { Redirect, Link } from 'react-router-dom';
import SideNav from '../../components/SideNav';
import TopNav from '../../components/TopNav';
import { pageUpdate } from '../../actions/settingsActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import CareersForm from './CareersForm';

class CareersNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "New Career"
    }

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.pageUpdate(this.state.pageTitle);
  }

  onSubmit() {
    this.props.history.push('/careers');
  }


  render() {
    if(!this.props.isAuthenticated) {
      return (
        <Redirect to='/login' />
      )
    } else {
      return <div className="page-careers">
        <SideNav/>
        <TopNav/>
        <div className="content">
          <div className="page-nav d-flex align-items-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col text-left">
                  <Link to="/career" className="btn btn-light">
                    <FontAwesomeIcon icon={faArrowLeft} transform="shrink-5"/> Back to Careers
                  </Link>
                </div>
              </div>
            </div>
          </div>


          <div className="content-inner">
            <div className="row">
              <div className="col">
                <div className="card card--dash">
                  <div className="card-body">
                    <CareersForm onSubmit={this.onSubmit}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state)
})

const mapDispatchToProps = (dispatch) => ({
  pageUpdate: (page) => {
    dispatch(pageUpdate(page))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(CareersNew);