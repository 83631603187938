export const DASHBOARD_REQUEST = '@@dashboards/DASHBOARD_REQUEST';
export const DASHBOARD_SUCCESS = '@@dashboards/DASHBOARD_SUCCESS';
export const DASHBOARD_FAILURE = '@@dashboards/DASHBOARD_FAILURE';

export const GRAPH_REQUEST = '@@dashboards/GRAPH_REQUEST';
export const GRAPH_SUCCESS = '@@dashboards/GRAPH_SUCCESS';
export const GRAPH_FAILURE = '@@dashboards/GRAPH_FAILURE';

export function dashboardRequest(payload) {
  return { type: DASHBOARD_REQUEST, payload }
}

export function dashboardSuccess(payload, params) {
  return { type: DASHBOARD_SUCCESS, payload, params };
}

export function dashboardFailure(payload) {
  return { type: DASHBOARD_FAILURE, payload };
}

export function graphRequest(payload) {
  return { type: GRAPH_REQUEST, payload };
}

export function graphSuccess(payload, params) {
  return { type: GRAPH_SUCCESS, payload, params };
}

export function graphFailure(payload, params){
  return { type: graphFailure, payload, params };
}