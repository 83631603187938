import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Axios from 'axios';
import { Formik } from 'formik';
import { withAuth } from '../../services/authServices';


const API = Axios.create({ baseURL: process.env.REACT_APP_APIURL });

class BlogMediaModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedImage: null
    }
    this.onAddImage = this.onAddImage.bind(this);
  }


  onAddImage() {
    this.state.selectedImage !== null  && this.props.onAddImage(this.props.medias[this.state.selectedImage]);
    this.props.toggle();
  }

  render() {
    return <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} centered={true} size="lg">
      <button className="close" style={{ position: 'absolute', top: '15px', right: '15px', zIndex: 2 }} onClick={this.props.toggle}>&times;</button>
      <ModalBody className="p-5">
        <div className="row">
          <div className="col-12">





            <Formik
              initialValues={{
                title: "",
                media: null,
                blog: 0
              }}
              onSubmit={(
                values,
                { setSubmitting, setErrors /* setValues and other goodies */ }
              ) => {
                const bodyFormData = new FormData();
                bodyFormData.set('title', values.title);
                values.blog && bodyFormData.set('blog', values.blog);
                bodyFormData.set('media', values.media);
                
                API.post(`blog_media/`, bodyFormData, { headers: withAuth({ 'Content-Type': 'multipart/form-data' }) })
                .then((res)=> {
                  setSubmitting(false);
                  this.props.addUploadedImage(res.data);
                })
                .catch((error)=> {
                  const err= {};
                  if (error.response) {
                    if (error.response.data) {
                      Object.entries(error.response.data).forEach((obj) => {
                        err[obj[0]] = obj[1][0]
                      });
                      setErrors(err);
                    }
                  } else {
                    err['non_field_errors'] = error.message
                    setErrors(err);
                  }
                  setSubmitting(false);
                });
              }}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
              }) => (

                <form onSubmit={(e) => {handleSubmit(e);e.stopPropagation()}}>
                  <div className="form-group">
                    <label htmlFor="imgMedia" className="font-size-lg">Image</label>
                    <div className="custom-file">
                      <input id="imgMedia" name="media" type="file" onChange={(event) => {
                        setFieldValue("media", event.currentTarget.files[0]);
                        // if (event.currentTarget.files[0]) {
                        //   const reader = new FileReader();
                
                        //   reader.onload = function (e) {
                        //     setFieldValue("display_media", e.target.result);
                        //   };
              
                        //   reader.readAsDataURL(event.currentTarget.files[0]);
                        // }
                      }} className="form-control" />
                      <label className="custom-file-label" htmlFor="imgMedia">{(values && values.media) ? values.media.name:"Select Image"}</label>
                      {touched.media && errors.media && <div className="invalid-feedback d-block">{errors.media}</div>}
                      <small className="form-text text-muted">PNG, JPG or GIF.</small>
                    </div>
                  </div>
                  <div className="form-group">
                  <label htmlFor="txtTitle">Title</label>
                    <input
                      id="txtTitle"
                      className="form-control"
                      type="text"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                    />
                    {touched.title && errors.title && <div className="invalid-feedback d-block">{errors.title}</div>}
                  </div>
                  <div className="form-group">
                    <div className="invalid-feedback d-block">{errors.non_field_errors}</div>
                  </div>
                  <div>
                    <button className="btn btn-primary text-uppercase font-weight-bold" type="submit" disabled={isSubmitting}>
                      { isSubmitting ? "Uploading..." :  "Upload Image"}
                    </button>
                  </div>

                </form>
              )}
              />










          </div>
          <div className="col-12">
            Select
            <div className="media-modal__collection">

              {this.props.medias.map((media, idx) => (
                <div key={idx} 
                  className={"media-modal__thumbnail" + (idx === this.state.selectedImage ? " selected": "")} 
                  style={{backgroundImage:`url(${media.media_medium})`}} 
                  onClick={() => {
                    this.setState({
                      selectedImage: idx
                    });
                  }}>
                </div>
              )
              )}
            </div>
          </div>
        </div>
        <div>
          <button className="btn btn-primary" onClick={this.onAddImage} disabled={this.state.selectedImage===null}>Add Selected Image</button>
        </div>
      </ModalBody>
    </Modal>
  }
}

export default BlogMediaModal;