/*
 * action types
 */

export const CAREERS_REQUEST = '@@careers/CAREERS_REQUEST';
export const CAREERS_SUCCESS = '@@careers/CAREERS_SUCCESS';
export const CAREERS_FAILURE = '@@careers/CAREERS_FAILURE';

export const CAREERS_SINGLE_REQUEST = '@@careers/CAREERS_SINGLE_REQUEST';
export const CAREERS_SINGLE_SUCCESS = '@@careers/CAREERS_SINGLE_SUCCESS';
export const CAREERS_SINGLE_FAILURE = '@@careers/CAREERS_SINGLE_FAILURE';

export const CAREERS_NEW_REQUEST = '@@careers/CAREERS_NEW_REQUEST';
export const CAREERS_NEW_SUCCESS = '@@careers/CAREERS_NEW_SUCCESS';
export const CAREERS_NEW_FAILURE = '@@careers/CAREERS_NEW_FAILURE';

export const CAREERS_DELETE = '@@careers/CAREERS_DELETE';

/*
 * action creators
 */

export function careersRequest(payload) {
  return { type: CAREERS_REQUEST, payload }
}

export function careersSuccess(payload) {
  return { type: CAREERS_SUCCESS, payload }
}

export function careersFailure(payload) {
  return { type: CAREERS_FAILURE, payload }
}

export function careersSingleRequest() {
  return { type: CAREERS_SINGLE_REQUEST }
}

export function careersSingleSuccess(payload) {
  return { type: CAREERS_SINGLE_SUCCESS, payload }
}

export function careersSingleFailure(payload) {
  return { type: CAREERS_SINGLE_FAILURE, payload }
}

export function careersNewSuccess(payload) {
  return { type: CAREERS_NEW_REQUEST, payload }
}

export function careersNewFailure(payload) {
  return { type: CAREERS_NEW_FAILURE, payload }
}

export function careersNewRequest() {
  return { type: CAREERS_NEW_REQUEST }
}

export function careersDelete(id) {
  return {type: CAREERS_DELETE, id}
}