import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Login from '../views/Login';
import Dashboard from '../views/Dashboard';
import Careers from '../views/Careers';
import CareersNew from '../views/Careers/CareersNew';
import CareersEdit from '../views/Careers/CareersEdit';
import Application, { ApplicationEdit } from '../views/Application';
import Blog, { BlogNew, BlogEdit } from '../views/Blog';
import Contact, { ContactSingle } from '../views/Contact';
import { AcademyApplicants, AcademyApplicationSingle } from '../views/Academy';

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/login" component={Login} />
      <PrivateRoute exact path="/" component={Dashboard} />
      <PrivateRoute exact path="/career" component={Careers} />
      <PrivateRoute exact path="/career/new" component={CareersNew} />
      <PrivateRoute exact path="/career/:id/edit" component={CareersEdit} />
      <PrivateRoute exact path="/application" component={Application} />
      <PrivateRoute
        exact
        path="/application/:careerId"
        component={Application}
      />
      <PrivateRoute
        exact
        path="/application/applicant/:application_id/"
        component={ApplicationEdit}
      />
      <PrivateRoute
        exact
        path="/academy/applicant/:application_id/"
        component={AcademyApplicationSingle}
      />
      <PrivateRoute
        exact
        path="/application/applicant/:application_id/:application_name/"
        component={ApplicationEdit}
      />
      <PrivateRoute exact path="/blog" component={Blog} />
      <PrivateRoute exact path="/blog/new" component={BlogNew} />
      <PrivateRoute exact path="/blog/:id/edit" component={BlogEdit} />
      <PrivateRoute exact path="/contact" component={Contact} />
      <PrivateRoute exact path="/contact/:id" component={ContactSingle} />
      <PrivateRoute
        exact
        path="/academy/applicants"
        component={AcademyApplicants}
      />
    </Switch>
  </Router>
);

export default Routes;
