import { getAccessToken } from '../selectors/authSelectors';
import { store } from "../store";

export function withAuth(headers = {}) {
  const state = store.getState();
  return {
    ...headers,
    'Authorization': `Bearer ${getAccessToken(state)}`
  }
}
