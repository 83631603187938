import API from './api';

const paginationLimit = parseInt(process.env.REACT_APP_PAGINATION_SIZE, 10);

class ApplicationApi {
  static getApplications(
    {
      pageSize = paginationLimit,
      orderBy = 'created',
      orderDir = 'DESC',
      search = undefined,
      page = 1,
      career_post = undefined,
      status = undefined
    },
    config
  ) {
    const params = { page_size: pageSize, page, orderBy, orderDir, search, career_post, status };
    return API.GET('application/list/', params, config);
  }

  static getApplicationsCountByStatus({ career_post = undefined }, config) {
    const params = { career_post };
    return API.GET('application/status-count/', params, config);
  }
}

export default ApplicationApi;

// import { applicationRequest, applicationSuccess, applicationFailure } from '../actions/applicationActions';
// import { careersSingleSuccess } from '../actions/careersActions';
// import { store } from "../store";
// import AuthApi from './authApi';

// class ApplicationApi extends AuthApi {
//   static getApplication(params) {
//     store.dispatch(applicationRequest());
//     return new Promise((resolve, reject) => {
//       this.GET('application/list/', params, { 'Content-Type': 'application/json' })
//         .then((res) => {
//           store.dispatch(applicationSuccess(res.data.data, params))
//           if (res.data.career) {
//             store.dispatch(careersSingleSuccess(res.data.data))
//           }
//           resolve(res);
//         })
//         .catch((error) => {
//           console.log(error);
//           reject(error);
//         });
//     });

//   }
// }

// export default ApplicationApi;
