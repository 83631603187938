import Axios from 'axios';
import API from './api';

const api = Axios.create({ baseURL: process.env.REACT_APP_APIURL });

class AuthApi extends API {
  static getRefreshedAccessToken(refresh) {
    return api.post(
      'auth/token/refresh/',
      { refresh },
      { headers: { 'Content-Type': 'application/json' } }
    );
  }

  static getAccessToken({ email, password }) {
    return api.post(
      'auth/token/obtain/',
      { email, password },
      { headers: { 'Content-Type': 'application/json' } }
    );
    // .then(res => {
    //   console.log("auth api success");
    //   return res.data;
    // }).catch(err => {
    //   // TODO: catch other errors.
    //   throw err.response;
    // });

    // return this.POST(
    //     'auth/token/obtain/',
    //     {
    //       email,
    //       password
    //     },
    //     { headers: { 'Content-Type': 'application/json' } }
    //   )
    // .then(res => {
    //   console.log('resolved', res);
    //   // resolve(res);
    //   return res;
    // })
    // .catch(error => {
    //   throw error;
    // });
  }
}

export default AuthApi;

// import { getRefreshToken, isAccessTokenExpired } from '../selectors/authSelectors';
// import { store } from "../store";
// import API from './api';
// import { withAuth } from '../services/authServices';
// import {loginSuccess} from '../actions/authActions'

// class AuthApi extends API {
//   static refreshAccessToken() {
//     const state = store.getState();

//     return new Promise((resolve, reject) => {
//       this.POST('auth/token/refresh/', {
//         refresh: getRefreshToken(state),
//       }, {
//         'Content-Type': 'application/json'
//       }).then((res) => {
//           console.log('new access and refresh token received.');
//           store.dispatch(loginSuccess(res.data));
//           resolve(res);
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });

//   }

//   static GET(path, params = {}, headers = {}) {
//     if (isAccessTokenExpired(store.getState())) {
//       console.log('expired.');
//       return new Promise((resolve, reject) => {
//         this.refreshAccessToken().then((data) => {
//           // make same request again.
//           resolve(this.GET(path, params, headers));
//         }).catch((error) => {
//           reject(error);
//         });
//       });
//     } else {

//       return new Promise((resolve, reject) => {

//         // NOTE: We may not need promise here if we have checked token
//         // expiration before each request.

//         API.GET(path, params, withAuth(headers)).then((res)=> {
//           resolve(res);
//         }).catch((error) => {

//           if (error.response.status === 401) {
//             // request access token from refresh token.
//             console.log('request new access token.');
//             this.refreshAccessToken().then((data) => {
//               // make same request again.
//               resolve(this.GET(path, params, headers));
//             }).catch((error) => {
//               reject(error);
//             });
//           } else {
//             reject(error);
//           }
//         })
//       });
//     }
//   }
// }

// export default AuthApi;
