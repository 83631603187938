import React, { Component } from 'react';
import { connect } from 'react-redux'

import Axios from 'axios';
import { Formik } from 'formik';
import { withAuth } from '../../services/authServices';

import ReactMarkdown from 'react-markdown';
// import Textarea from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BlogMediaModal from './BlogMediaModal';
import { faImages } from '@fortawesome/free-solid-svg-icons';

// import remark from 'remark';
// import reactRenderer from 'remark-react';
import { getBlogMedias } from '../../selectors/blogSelectors';
import { blogAddMedia } from '../../actions/blogActions';


const API = Axios.create({ baseURL: process.env.REACT_APP_APIURL });

// // See https://github.com/aknuds1/html-to-react#with-custom-processing-instructions
// // for more info on the processing instructions
// const parseHtml = (a) => {
//   console.log(a);
//   return a;
// }


// class MyImage extends Component {
//   render() {
//     console.log(this.props);
//     return <div>THis is image</div>
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(BlogForm);


class BlogForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addMediaModalOpen: false,
      medias: [], //uploaded medias
      used_medias: this.props.blog ? { ...this.props.blog.medias } : {}
    }
    this.refContent = React.createRef();
    this.addContentImage = this.addContentImage.bind(this);
    this.addMediaModalToggle = this.addMediaModalToggle.bind(this);
    this.addUploadedImage = this.addUploadedImage.bind(this);
    this.getImageUrl = this.getImageUrl.bind(this);

  }

  getImageUrl(mdImage) {
    console.log(mdImage.src)
    const mdImageSrc = mdImage.src.split(",");
    const image = this.props.blogMedias[mdImageSrc[0]]
    if (image) {
      if (mdImageSrc.length === 1) {
        return <img src={image.media_medium} alt={this.title} />
      } else {
        return <img src={image.media_medium} alt={this.title} className={mdImageSrc[1]} />
      }
    } else {
      return "";
    }
  }

  componentDidMount() {
    // if (this.props.blog) {
    //   this.setState({ used_medias: this.props.blog.medias});
    //   console.log("EDIT");
    // }
    API.get(`blog_media/`, {
      headers: withAuth()
    }).then(res => {
      let d = [...res.data];
      let d_obj = {};
      res.data.forEach(media => {
        d_obj[media.id] = media;
        this.props.blogAddMedia(media);
      });
      this.setState({
        medias: d,
        used_medias: {
          ...this.state.used_medias,
          ...d_obj
        }
      });

    }).catch(error => {
      console.log(error);
    });
  }

  addMediaModalToggle() {
    this.setState({
      addMediaModalOpen: !this.state.addMediaModalOpen
    });
  }

  addContentImage(content, setFieldValue) {
    this.addMediaModalToggle();
    // console.log("adding.");
    // // console.log(this.refContent.current);
    // let l = this.refContent.selectionStart;

    // setFieldValue("content", content.substr(0,l) + "hahaha" + content.substr(l));
    // this.refContent.focus();
    // this.refContent.selectionStart = l + 6;
  }

  addUploadedImage(image) {
    console.log(image);
    let updatedMedias = [...this.state.medias];
    updatedMedias.unshift(image);

    // console.log([...this.state.medias].unshift(image));
    this.setState({
      medias: updatedMedias,
      used_medias: {
        ...this.state.used_medias,
        [image.id]: image
      }
    });
  }

  onAddImage() {
    console.log(this.values);
    console.log(this);
  }

  render() {
    return <Formik
      validateOnChange={false}
      initialValues={this.props.blog ? { ...this.props.blog, cover_image: null, display_cover_image: this.props.blog.cover_image_medium } : {
        title: "",
        sub_title: "",
        content: "",
        excerpt: "",
        cover_image: null,
        author: "",
        tags: "",
        published: false,
        featured: false
      }}
      onSubmit={(
        values,
        { setSubmitting, setErrors /* setValues and other goodies */ }
      ) => {

        // TODO: check if auth expired. 

        if (this.props.blog) {
          // EDIT POST
          const bodyFormData = new FormData();
          bodyFormData.set('title', values.title);
          bodyFormData.set('sub_title', values.sub_title);
          bodyFormData.set('content', values.content);
          bodyFormData.set('excerpt', values.excerpt);
          values.cover_image && bodyFormData.set('cover_image_original', values.cover_image);
          bodyFormData.set('author', values.author);
          bodyFormData.set('tags', values.tags);
          bodyFormData.set('published', values.published);
          bodyFormData.set('featured', values.featured);

          API.put(`blog/${this.props.blog.id}/`, bodyFormData, { headers: withAuth({ 'Content-Type': 'multipart/form-data' }) })
            .then((res) => {
              setSubmitting(false);
              console.log(this.props);
              this.props.onSubmit();
              // this.props.loginSuccess(res.data);
            })
            .catch((error) => {
              const err = {};
              if (error.response) {
                if (error.response.data) {
                  Object.entries(error.response.data).forEach((obj) => {
                    err[obj[0]] = obj[1][0]
                  });
                  setErrors(err);
                }
                // this.props.loginFailure(error.response);
              } else {
                // this.props.loginFailure({
                //   data: {'non_field_errors': error.message},
                // });
                err['non_field_errors'] = error.message
                setErrors(err);
              }
              setSubmitting(false);
            });

        } else {
          // NEW POST
          const bodyFormData = new FormData();
          bodyFormData.set('title', values.title);
          bodyFormData.set('sub_title', values.sub_title);
          bodyFormData.set('content', values.content);
          bodyFormData.set('excerpt', values.excerpt);
          values.cover_image && bodyFormData.set('cover_image_original', values.cover_image);
          bodyFormData.set('author', values.author);
          bodyFormData.set('tags', values.tags);
          bodyFormData.set('published', values.published);
          bodyFormData.set('featured', values.featured);

          API.post('blog/', bodyFormData, { headers: withAuth({ 'Content-Type': 'multipart/form-data' }) })
            .then((res) => {
              setSubmitting(false);
              console.log(this.props);
              this.props.onSubmit();
              // this.props.loginSuccess(res.data);
            })
            .catch((error) => {
              const err = {};
              if (error.response) {
                if (error.response.data) {
                  Object.entries(error.response.data).forEach((obj) => {
                    err[obj[0]] = obj[1][0]
                  });
                  setErrors(err);
                }
                // this.props.loginFailure(error.response);
              } else {
                // this.props.loginFailure({
                //   data: {'non_field_errors': error.message},
                // });
                err['non_field_errors'] = error.message
                setErrors(err);
              }
              setSubmitting(false);
            });
        }


      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
          <form onSubmit={handleSubmit}>
            <input
              id="txtId"
              className="form-control"
              type="hidden"
              name="id"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.id}
            />

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="txtTitle">Title</label>
                  <input
                    id="txtTitle"
                    className="form-control"
                    type="text"
                    name="title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                  />
                  {touched.title && errors.title && <div className="invalid-feedback d-block">{errors.title}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="txtAuthor">Author</label>
                  <input
                    id="txtAuthor"
                    className="form-control"
                    type="text"
                    name="author"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.author}
                  />
                  {touched.author && errors.author && <div className="invalid-feedback d-block">{errors.author}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="txtSubTitle">Sub Title</label>
                  <input
                    id="txtSubTitle"
                    className="form-control"
                    type="text"
                    name="sub_title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.sub_title}
                  />
                  {touched.sub_title && errors.sub_title && <div className="invalid-feedback d-block">{errors.sub_title}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="imgCover" className="font-size-lg">Cover Image</label>
                  <div className="custom-file">
                    <input id="imgCover" name="cover_image" type="file" onChange={(event) => {
                      setFieldValue("cover_image", event.currentTarget.files[0]);
                      if (event.currentTarget.files[0]) {
                        const reader = new FileReader();

                        reader.onload = function (e) {
                          setFieldValue("display_cover_image", e.target.result);
                        };

                        reader.readAsDataURL(event.currentTarget.files[0]);
                      }
                    }} className="form-control" />
                    <label className="custom-file-label" htmlFor="imgCover">{(values && values.cover_image) ? values.cover_image.name : "Select Image"}</label>
                    {touched.cover_image && errors.cover_image && <div className="invalid-feedback d-block">{errors.cover_image}</div>}
                    <small className="form-text text-muted">PNG, JPG or GIF.</small>
                  </div>
                </div>


                <div className="form-group">
                  <label htmlFor="txtContent">Content</label>

                  <div className="pb-2">

                    <button className="btn btn-outline-primary" onClick={(e) => {
                      this.addContentImage(values.content, setFieldValue)

                      console.log("adding.");
                      // console.log(this.refContent.current);

                      // let l = this.refContent.selectionStart;

                      // setFieldValue("content", values.content.substr(0,l) + "hahaha" + values.content.substr(l));
                      // this.refContent.focus();
                      // this.refContent.selectionStart = l + 6;

                    }

                    } type="button"> <FontAwesomeIcon icon={faImages} /> Add Image</button>
                    <BlogMediaModal isOpen={this.state.addMediaModalOpen} toggle={this.addMediaModalToggle} medias={this.state.medias} addUploadedImage={this.addUploadedImage} onAddImage={(media) => {
                      console.log(media);

                      let l = this.refContent.current.selectionStart;
                      // const rr = '!['+ media.title  +'](' + media.id + ')';

                      // setFieldValue("content", values.content.substr(0,l) + rr + values.content.substr(l));
                      // console.log(this.refContent);
                      // this.refContent.current.focus();
                      // this.refContent.selectionStart = l + 6;

                      // {{> image id=1 caption="this is a sample caption." align="left"}}
                      const rr = `{{> image id=${media.id} caption="${media.title}" }}`;
                      setFieldValue("content", values.content.substr(0, l) + rr + values.content.substr(l));
                      this.refContent.current.focus();

                    }} />
                  </div>

                  <textarea
                    ref={this.refContent}
                    // inputRef={tag => (this.refContent = tag)} 
                    rows="20"
                    id="txtContent"
                    className="form-control"
                    name="content"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.content}
                  />
                  {touched.content && errors.content && <div className="invalid-feedback d-block">{errors.content}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="txtExcerpt">Excerpt</label>
                  <textarea
                    rows="3"
                    id="txtExcerpt"
                    className="form-control"
                    name="excerpt"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.excerpt}
                  />
                  {touched.excerpt && errors.excerpt && <div className="invalid-feedback d-block">{errors.excerpt}</div>}
                </div>
                <div className="form-group">
                  <label htmlFor="txtTags">Tags</label>
                  <input
                    id="txtTags"
                    className="form-control"
                    type="text"
                    name="tags"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tags}
                  />
                  {touched.tags && errors.tags && <div className="invalid-feedback d-block">{errors.tags}</div>}
                  <small className="form-text text-muted">Separate tags by comma</small>
                </div>

                <div className="form-check">
                  <input className="form-check-input" type="checkbox" name="featured" id="chkFeatured"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.featured}
                    checked={values.featured === true}
                  />
                  <label className="form-check-label" htmlFor="chkFeatured">
                    Featured Blog
                </label>
                  {touched.featured && errors.featured && <div className="invalid-feedback d-block">{errors.featured}</div>}
                </div>
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" name="published" id="chkPublished"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.published}
                    checked={values.published === true}
                  />
                  <label className="form-check-label" htmlFor="chkPublished">
                    Published
                </label>
                  {touched.published && errors.published && <div className="invalid-feedback d-block">{errors.published}</div>}
                </div>

                <div className="form-group">
                  <div className="invalid-feedback d-block">{errors.non_field_errors}</div>
                </div>
                <div>
                  <button className="btn btn-primary text-uppercase font-weight-bold" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (this.props.blog ? "Updating Blog" : "Posting Blog") : (this.props.blog ? "Update Blog" : "Post Blog")}
                  </button>
                </div>


              </div>
              <div className="col-md-6">
                {/* <div className="form-group"> */}
                {/* <label className="text-muted">Blog Preview</label> */}
                <div className="blog-preview">
                  <h1>{values.title}</h1>
                  <p className="text-muted"><strong>{values.sub_title}</strong></p>
                  {values.display_cover_image ? <img src={values.display_cover_image} alt="" /> : ""}
                  {/* <div className="markdown markdown--blog mt-4">

                    {remark().use(reactRenderer, {
                      sanitize: false,
                      remarkReactComponents: {
                        img: this.getImageUrl
                      }
                    }).processSync(values.content).contents}
                  </div>
 */}


                  <ReactMarkdown source={values.content} className="markdown markdown--blog"
                    escapeHtml={false}
                    plugins={[
                      [
                        require('remark-shortcodes'),
                        { startBlock: "{{>", endBlock: "}}" }
                      ]
                    ]}
                    // astPlugins={[parseHtml]}
                    // transformImageUri={(uri) => {
                    //   // console.log(this.state.used_medias[a].media_medium);
                    //   return this.state.used_medias[uri].media_medium;
                    // }}
                    renderers={{
                      // image: (a, b) => {
                      //   // console.log(a, b);
                      //   return <div>IMGE>...</div>;
                      // },

                      shortcode: (node) => {
                        if (node.identifier === "image") {
                          const alignMap = {
                            "left": "align-left",
                            "right": "align-right"
                          }
                          const image = this.props.blogMedias[node.attributes.id];
                          if (image) {
                            return <figure className={alignMap[node.attributes.align]}>
                              <img src={image.media_medium} alt={image.title} />
                              <figcaption>{node.attributes.caption}</figcaption>
                            </figure>
                          }
                        }
                        return "";
                      }
                    }} />
                </div>
                {/* </div> */}

              </div>
            </div>
          </form>
        )}
    />

  }
}

const mapStateToProps = (state) => ({
  blogMedias: getBlogMedias(state)
})

const mapDispatchToProps = (dispatch) => ({
  blogAddMedia: (payload) => {
    dispatch(blogAddMedia(payload));
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(BlogForm);