import { select, call, put, all, takeLatest } from 'redux-saga/effects';
import {
  getAccessToken,
  isAccessTokenExpired,
  getRefreshToken,
  isRefreshTokenExpired
} from '../selectors/authSelectors';
import {
  refreshAccessToken,
  RESUME_AUTH_REQUEST,
  logout
} from '../actions/authActions';

// list of postponed actions that needs renewed access token.
let postponedAuthSagas = [];

export function* apiWithAuth(api, action, { headers = {}, ...rest } = {}) {
  const data = action.payload;
  const accessToken = yield select(getAccessToken);
  const refreshToken = yield select(getRefreshToken);
  const accessExpired = yield select(isAccessTokenExpired);
  const refreshExpired = yield select(isRefreshTokenExpired);

  if (refreshExpired) {
    yield put(logout());
    return null;
  }

  if (accessExpired) {
    // postpone action until new access token is received.
    postponedAuthSagas.push(action);
    yield put(refreshAccessToken(refreshToken));
    return null;
  }

  const props = {
    ...rest,
    headers: {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = yield call(api, data, props);
    return response;
  } catch (err) {
    if (!err.status) {
      // err.statusText = 'Network Error';
      // TODO: Call Global Error to be displayed in a Modal.
    }
    throw err;
  }

  // if (response) {
  //   // if (
  //   //   response.statusCode === 401 &&
  //   //   response.message !== 'refreshToken is expired'
  //   // ) {
  //   //   const isRefreshed = yield call(tryRefreshTokens);
  //   //   if (isRefreshed) {
  //   //     return yield call(apiCaller, props);
  //   //   }
  //   // }
  //   return response;
  // }
  // return {};
}

/**
 * Iterate through all postponed actions and resume all.
 * Then clears postponed actions.
 */
function* resumeRequest() {
  for (let i = 0; i < postponedAuthSagas.length; i += 1) {
    yield put(postponedAuthSagas[i]);
  }
  postponedAuthSagas = [];
}

export default function* rootSaga() {
  yield all([takeLatest(RESUME_AUTH_REQUEST, resumeRequest)]);
}
