import API from './api';

const paginationLimit = parseInt(process.env.REACT_APP_PAGINATION_SIZE, 10);

class AcademyApplicationApi {
  static list(
    {
      pageSize = paginationLimit,
      orderBy = 'created',
      orderDir = 'DESC',
      search = undefined,
      page = 1,
      status = undefined
    },
    config
  ) {
    const params = { page_size: pageSize, page, orderBy, orderDir, search, status };
    return API.GET('academy/registration', params, config);
  }

  static get({ contactId = undefined }, config) {
    const params = {};
    return API.GET(`academy/registration/${contactId}/`, params, config);
  }

  static updateStatus(appId, body, config) {
    // const params ={}
    // return API.PATCH(`academy/`)
    API.GET(`academy/registration/${appId}/`, body, config);
  }
}

export default AcademyApplicationApi;
