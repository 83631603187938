import React, { Component } from 'react';
import { connect } from 'react-redux'

import Axios from 'axios';
import { Formik } from 'formik';
import { withAuth } from '../../services/authServices';

import ReactMarkdown from 'react-markdown';
import Textarea from 'react-textarea-autosize';

const API = Axios.create({ baseURL: process.env.REACT_APP_APIURL });

class CareersForm extends Component {
  constructor(props) {
    super(props); 
    this.state = {
    }
  }
  render() {
    return <Formik
      // loginSuccess={this.props.loginSuccess}
      initialValues={this.props.career ? {...this.props.career} : {
        title: "",
        description: "",
        deadline: "",
        location: "",
        active: true
      }}
      onSubmit={(
        values,
        { setSubmitting, setErrors /* setValues and other goodies */ }
      ) => {
        
        // TODO: check if auth expired. 
        
        if (this.props.career) {
          // EDIT POST
          API.put(`careers/${this.props.career.id}/`, values, { headers: withAuth({ 'Content-Type': 'application/json' }) })
          .then((res)=> {
            setSubmitting(false);
            console.log(this.props);
            this.props.onSubmit();
            // this.props.loginSuccess(res.data);
          })
          .catch((error)=> {
            const err= {};
            if (error.response) {
              if (error.response.data) {
                Object.entries(error.response.data).forEach((obj) => {
                  err[obj[0]] = obj[1][0]
                });
                setErrors(err);
              }
              // this.props.loginFailure(error.response);
            } else {
              // this.props.loginFailure({
              //   data: {'non_field_errors': error.message},
              // });
              err['non_field_errors'] = error.message
              setErrors(err);
            }
            setSubmitting(false);
          });        

        } else {
          // NEW POST
          API.post('careers/', values, { headers: withAuth({ 'Content-Type': 'application/json' }) })
          .then((res)=> {
            setSubmitting(false);
            console.log(this.props);
            this.props.onSubmit();
            // this.props.loginSuccess(res.data);
          })
          .catch((error)=> {
            const err= {};
            if (error.response) {
              if (error.response.data) {
                Object.entries(error.response.data).forEach((obj) => {
                  err[obj[0]] = obj[1][0]
                });
                setErrors(err);
              }
              // this.props.loginFailure(error.response);
            } else {
              // this.props.loginFailure({
              //   data: {'non_field_errors': error.message},
              // });
              err['non_field_errors'] = error.message
              setErrors(err);
            }
            setSubmitting(false);
          });        
        }
        

      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <input
              id="txtId"
              className="form-control"
              type="hidden"
              name="id"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.id}
            />


          <div className="row">
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="txtTitle">Title</label>

                

                <input
                  id="txtTitle"
                  className="form-control"
                  type="text"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                />
                {touched.title && errors.title && <div className="invalid-feedback d-block">{errors.title}</div>}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="txtLocation">Location</label>
                <input
                  id="txtLocation"
                  className="form-control"
                  type="text"
                  name="location"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.location}
                />
                {touched.location && errors.location && <div className="invalid-feedback d-block">{errors.location}</div>}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label htmlFor="txtDeadline">Deadline</label>
                <input
                  id="txtDeadline"
                  className="form-control"
                  type="date"
                  name="deadline"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.deadline}
                />
                {touched.deadline && errors.deadline && <div className="invalid-feedback d-block">{errors.deadline}</div>}
              </div>
            
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
          
          
              <div className="form-group">
                <label htmlFor="txtDescription">Description</label>
                <Textarea
                  rows="10"
                  id="txtDescription"
                  className="form-control"
                  type="description"
                  name="description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                />
                {touched.description && errors.description && <div className="invalid-feedback d-block">{errors.description}</div>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="text-muted">Description Preview</label>
                <ReactMarkdown source={values.description} />
              </div>
            </div>
          </div>

          
          

          
          <div className="form-check">
            <input className="form-check-input" type="checkbox" name="active" id="chkActive"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.active}
              checked={values.active === true}
            />
            <label className="form-check-label" htmlFor="chkActive">
              Active
            </label>
            {touched.active && errors.active && <div className="invalid-feedback d-block">{errors.active}</div>}
          </div>
          <div className="form-group">
            <div className="invalid-feedback d-block">{errors.non_field_errors}</div>
          </div>
          <div>
            <button className="btn btn-primary text-uppercase font-weight-bold" type="submit" disabled={isSubmitting}>
              { isSubmitting ? (this.props.career ? "Updating Job" : "Posting Job") :  (this.props.career ? "Update Job" : "Post Job")}
            </button>
          </div>
        </form>
      )}
    />

  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(CareersForm);