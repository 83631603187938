import * as blog from '../actions/blogActions'

const initialState = {
  blogRequest: false,
  blogData: {},
  blogList: [],
  blogErrors: {},
  blogSingleErrors: {},
  blogNewRequest: false,
  blogMedias: {}
}

export default (state=initialState, action) => {
  switch(action.type) {
    case blog.BLOG_REQUEST:
      return {
        ...state,
        blogRequest: true
      }

    case blog.BLOG_SUCCESS:
      const blogList = [];
      const blogData = {};
      action.payload.forEach((blog_post => {
        blogList.push(blog_post.id);
        blogData[blog_post.id] = blog_post
      }));
      return {
        ...state,
        blogData: {
          ...state.blogData,
          ...blogData
          // [action.payload.id]: action.payload
        },
        // blogList: [...state.blogList, action.payload.id]
        blogList: blogList
      }
    case blog.BLOG_FAILURE:
      return {
        ...state,
        errors: 
        action.payload.data || 
           {'non_field_errors': action.payload.statusText},
      }
    case blog.BLOG_DELETE:
      return {
        ...state,
        blogList: state.blogList.filter(id => {
          return id !== action.id
        })
      }
    case blog.BLOG_SINGLE_SUCCESS:
      return {
        ...state,
        blogData: {
          ...state.blogData,
          [action.payload.id]: action.payload
        }
      }
    case blog.BLOG_SINGLE_FAILURE:
      return {
        ...state,
        blogSingleErrors:{
          error:"error" // TODO
        }
      }
    case blog.BLOG_ADD_MEDIA:
      return {
        ...state,
        blogMedias: {
          ...state.blogMedias,
          [action.payload.id]: action.payload 
        }
      }
    default:
      return state
  }
}

export function getBlog(state) {
  return function(blog_id) {return state.blogData[blog_id];}
}

export function getBlogList(state) {
  return state.blogList;
}

export function getBlogData(state) {
  return state.blogData;
}

export function getBlogMedias(state) {
  return state.blogMedias;
}
