/*
 * action types
 */

export const APPLICATION_REQUEST = '@@careers/APPLICATION_REQUEST';
export const APPLICATION_SUCCESS = '@@careers/APPLICATION_SUCCESS';
export const APPLICATION_FAILURE = '@@careers/APPLICATION_FAILURE';

export const APPLICATION_SINGLE_REQUEST = '@@careers/APPLICATION_SINGLE_REQUEST';
export const APPLICATION_SINGLE_SUCCESS = '@@careers/APPLICATION_SINGLE_SUCCESS';
export const APPLICATION_SINGLE_FAILURE = '@@careers/APPLICATION_SINGLE_FAILURE';

// export const APPLICATION_NEW_REQUEST = '@@careers/APPLICATION_NEW_REQUEST';
// export const APPLICATION_NEW_SUCCESS = '@@careers/APPLICATION_NEW_SUCCESS';
// export const APPLICATION_NEW_FAILURE = '@@careers/APPLICATION_NEW_FAILURE';

export const APPLICATION_DELETE = '@@careers/APPLICATION_DELETE';

export const APPLICATION_COUNT_BY_STATUS_REQUEST = '@@careers/APPLICATION_COUNT_BY_STATUS_REQUEST';
export const APPLICATION_COUNT_BY_STATUS_SUCCESS = '@@careers/APPLICATION_COUNT_BY_STATUS_SUCCESS';
export const APPLICATION_COUNT_BY_STATUS_FAILURE = '@@careers/APPLICATION_COUNT_BY_STATUS_FAILURE';

/*
 * action creators
 */

export function applicationRequest(payload) {
  return { type: APPLICATION_REQUEST, payload };
}

export function applicationSuccess(payload, params) {
  return { type: APPLICATION_SUCCESS, payload, params };
}

export function applicationFailure(payload) {
  return { type: APPLICATION_FAILURE, payload };
}

export function applicationSingleRequest() {
  return { type: APPLICATION_SINGLE_REQUEST };
}

export function applicationSingleSuccess(payload) {
  return { type: APPLICATION_SINGLE_SUCCESS, payload };
}

export function applicationSingleFailure(payload) {
  return { type: APPLICATION_SINGLE_FAILURE, payload };
}

export function applicationDelete(id) {
  return { type: APPLICATION_DELETE, id };
}

export function applicationCountByStatusRequest(payload) {
  return { type: APPLICATION_COUNT_BY_STATUS_REQUEST, payload};
}

export function applicationCountByStatusSuccess(payload) {
  return { type: APPLICATION_COUNT_BY_STATUS_SUCCESS, payload };
}

export function applicationCountByStatusFailure(payload) {
  return { type: APPLICATION_COUNT_BY_STATUS_FAILURE, payload };
}

