import API from './api';
class DashboardApi {
  // static getDashboardCards1(
  //   {
  //     pageSize = paginationLimit,
  //     orderBy = 'created',
  //     orderDir = 'DESC',
  //     search = undefined,
  //     page = 1,
  //     career_post = undefined
  //   },
  //   config
  // ) {
  //   const params = { page_size: pageSize, page, orderBy, orderDir, search, career_post };
  //   return API.GET('application/list/', params, config);
  // }

  // eslint-disable-next-line no-empty-pattern
  static getDashboardCards({}, config) {
    return API.GET('dashboard/cards/', {}, config);
  }

  // eslint-disable-next-line no-empty-pattern
  static getDashboardGraph({}, config) {
    return API.GET('dashboard/graph/', {}, config);
  }
}

export default DashboardApi;
