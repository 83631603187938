/*
 * action types
 */

export const SIDENAV_EXPAND = '@@settings/SIDENAV_EXPAND';
export const SIDENAV_COLLAPSE = '@@settings/SIDENAV_COLLAPSE';
export const PAGE_UPDATE = '@@settings/PAGE_UPDATE';

/*
 * action creators
 */

export function sidenavExpand() {
  return { type: SIDENAV_EXPAND };
}

export function sidenavCollapse() {
  return { type: SIDENAV_COLLAPSE };
}

export function pageUpdate(pageTitle) {
  return { type: PAGE_UPDATE, pageTitle };
}
