import { put, takeEvery, all } from 'redux-saga/effects';

import academyApplicationApi from '../api/academyApplicationApi';
import {
  ACADEMY_APPLICATION_LIST_REQUEST,
  academyApplicationListSuccess,
  academyApplicationListFailure,
  // ACADEMY_APPLICATION_REQUEST,
  // academyApplicationSuccess,
  // academyApplicationFailure
} from '../actions/academyApplicationActions';

import { apiWithAuth } from './requestSagas';
import { pageUpdate } from '../actions/settingsActions';

function* academyApplicationListRequest(action) {
  try {
    const result = yield apiWithAuth(academyApplicationApi.list, action);
    // const updatedStatus = yield apiWithAuth(academyApplicationApi.updateStatus, action);
    yield put(academyApplicationListSuccess(result.data, action.payload));
    yield put(pageUpdate(`Academy Application`));
  } catch (error) {
    yield put(academyApplicationListFailure(error));
    yield put(pageUpdate(`Academy Application`));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACADEMY_APPLICATION_LIST_REQUEST, academyApplicationListRequest),
  ]);
}
