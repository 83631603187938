import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const Pagination = ({ page, pageSize, total, onPageChange }) => {
  if (total === 0) return null;
  return (
    <Fragment>
      <button
        type="button"
        disabled={page === 1}
        className="btn btn-link"
        onClick={() => onPageChange(page - 1)}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </button>
      <div className="px-2 d-inline-block align-middle">
        <strong>{(page - 1) * pageSize + 1}</strong>
        <span className="text-muted"> - </span>
        <strong>{pageSize * page < total ? pageSize * page : total}</strong>
        <span className="text-muted">{` of ${total}`}</span>
      </div>
      <button
        type="button"
        disabled={page * pageSize >= total}
        className="btn btn-link"
        onClick={() => onPageChange(page + 1)}
      >
        <FontAwesomeIcon icon={faAngleRight} />
      </button>
    </Fragment>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default Pagination;
