import { combineReducers } from 'redux';
import authReducers from './authReducers';
import settingsReducers from './settingsReducers';
import careersReducers from './careersReducers';
import applicationReducers from './applicationReducers';
import blogReducers from './blogReducers';
import contactReducers from './contactReducers';
import academyApplicationReducers from './academyApplicationReducers';
import dashboardReducers from './dashboardReducers';

const reducers = combineReducers({
  auth: authReducers,
  settings: settingsReducers,
  careers: careersReducers,
  application: applicationReducers,
  blog: blogReducers,
  contact: contactReducers,
  academyApplication: academyApplicationReducers,
  dashboard: dashboardReducers
});

export default reducers;
