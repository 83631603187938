import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const RefreshButton = ({ onClick, isRefreshing, className }) => {
  return (
    <button
      type="button"
      className={`btn btn-link + ${className}`}
      onClick={onClick}
      disabled={isRefreshing}
    >
      <FontAwesomeIcon icon={faSyncAlt} spin={isRefreshing} />
    </button>
  );
};

RefreshButton.propTypes = {
  onClick: PropTypes.func,
  isRefreshing: PropTypes.bool,
  className: PropTypes.string
};

RefreshButton.defaultProps = {
  onClick: null,
  isRefreshing: false,
  className: ''
};

export default RefreshButton;
