import { all } from 'redux-saga/effects';
import authSagas from './authSagas';
import applicationSagas from './applicationSagas';
import careersSagas from './careersSagas';
import contactSagas from './contactSagas';
import academyApplicationSagas from './academyApplicationSagas';
import requestSagas from './requestSagas';
import dashboardSagas from './dashboardSagas';
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    requestSagas(),
    authSagas(),
    applicationSagas(),
    careersSagas(),
    contactSagas(),
    academyApplicationSagas(),
    dashboardSagas()
  ]);
}
