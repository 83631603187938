import React, { Component } from 'react';
import { connect } from 'react-redux'

import { isAuthenticated } from '../../selectors/authSelectors'
import { Redirect, Link } from 'react-router-dom';
import SideNav from '../../components/SideNav';
import TopNav from '../../components/TopNav';
import { pageUpdate } from '../../actions/settingsActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';

import BlogForm from './BlogForm';
import { getBlog } from '../../selectors/blogSelectors';

import Axios from 'axios';
import { blogSingleSuccess, blogSingleFailure, blogAddMedia } from '../../actions/blogActions';

const API = Axios.create({ baseURL: process.env.REACT_APP_APIURL })

class BlogEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageTitle: "Blog",
      loading: false,
      loaded: false
    }

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: true
    });

    API.get(`blog/${this.props.match.params.id}/`)
    .then((res)=> { 
      if (res.data) {
        this.props.blogSingleSuccess(res.data);
        this.props.pageUpdate(res.data.title);
        Object.entries(res.data.medias).forEach(([key, media]) => {
          this.props.blogAddMedia(media);
        });
      }
      this.setState({
        loading: false,
        loaded: true
      });

    })
    .catch((error)=> { 
      console.log(error);
      this.setState({
        error: true,
        loading: false
      })
    });

  }

  onSubmit() {
    this.props.history.push('/blog');
  }


  render() {
    if(!this.props.isAuthenticated) {
      return (
        <Redirect to='/login' />
      )
    } else {
      return <div className="page-blog">
        <SideNav/>
        <TopNav/>
        <div className="content">
          <div className="page-nav d-flex align-items-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col text-left">
                  <Link to="/blog" className="btn btn-light">
                    <FontAwesomeIcon icon={faArrowLeft} transform="shrink-5"/> Back to Blogs
                  </Link>
                </div>
                <div className="col text-right">
                  <Link to="/blog/new" className="btn btn-primary">
                    <FontAwesomeIcon icon={faPlus} transform="shrink-5"/> New Blog
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="content-inner">
            <div className="row">
              <div className="col">
                <div className="card card--dash">


                  <div className="card-body">

                    {this.state.loaded && <BlogForm onSubmit={this.onSubmit} blog={this.props.blog(this.props.match.params.id)}/>}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: isAuthenticated(state),
  blog: getBlog(state),
})

const mapDispatchToProps = (dispatch) => ({
  pageUpdate: (page) => {
    dispatch(pageUpdate(page))
  },
  blogSingleSuccess: (payload) => {
    dispatch(blogSingleSuccess(payload))
  },
  blogSingleFailure: () => {
    dispatch(blogSingleFailure());
  },
  blogAddMedia: (payload) => {
    dispatch(blogAddMedia(payload));
  }


})

export default connect(mapStateToProps, mapDispatchToProps)(BlogEdit);