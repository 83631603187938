import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { debounce } from 'lodash';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import SideNav from '../../components/SideNav';
import TopNav from '../../components/TopNav';
import {
  getContactList,
  getContactData,
  getContactLoading,
  getContactPagination
} from '../../selectors/contactSelectors';
import { pageUpdate } from '../../actions/settingsActions';
import { contactSingleRequest } from '../../actions/contactActions';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderBy: '',
      orderAsc: false,
      search: ''
    };
    this.fetchContact = this.fetchContact.bind(this);
    this.orderBy = this.orderBy.bind(this);
  }

  componentDidMount() {
    const { pageUpdateConnect } = this.props;
    pageUpdateConnect('Contact');
    this.fetchContact();
  }

  fetchContact() {
    const {
      match,
      contactSingleRequestConnect
    } = this.props;

    // const { search, orderBy, orderAsc } = this.state;

    const params = {
      contactId: match.params.id
    };

    contactSingleRequestConnect(params);
  }

  orderBy(field) {
    const { orderBy, orderAsc } = this.state;
    this.setState(
      {
        orderBy: field,
        orderAsc: field === orderBy ? !orderAsc : true
      },
      this.fetchContact
    );
  }


  render() {
    const { contactData, match } = this.props;

    // const {} = this.state;

    const contact = contactData[match.params.id];
    if (!contact) return 'Loading.';

    return (
      <div className="page-contact">
        <SideNav />
        <TopNav />
        <div className="content">
          <div className="page-nav d-flex align-items-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <Link to="/contact" className="btn btn-light">
                    <FontAwesomeIcon icon={faArrowLeft} transform="shrink-5" />
                    Back to Contacts
                  </Link>
                </div>
                <div className="col text-right">
                  {/* right */}
                </div>
              </div>
            </div>
          </div>

          <div className="content-inner">
            <div className="row">
              <div className="col">
                <div className="card card--dash">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-10 col-lg-8 col-xl-6">
                        <span className="label label--display mb-1">Sent From</span>
                        <h4>{contact.email !== '' ? contact.email : contact.phone}</h4>
                        {contact.email !== '' ? <h5 className="text-muted mb-4">{contact.phone}</h5>: null}
                        <span className="label label--display mb-1">Message</span>
                        <div>{contact.description.split('\n').map((d, idx) => <p key={idx}>{d}</p>)}</div>
                      </div>
                    </div>
                    <div className="divider" style={{display: 'none'}}/>
                    <div className="row" style={{display: 'none'}}>
                      <div className="col-md-10 col-lg-8 col-xl-6">
                        <form action="post">
                          <div className="form-group">
                            <label className="label label--display">Reply</label>
                            <textarea name="" id="" className="form-control"></textarea>
                          </div>
                          <div>
                            <button type="button" className="btn btn-primary">Send</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Contact.propTypes = {};

Contact.defaultProps = {
  isLoading: false
};

const mapStateToProps = state => ({
  pagination: getContactPagination(state),
  isLoading: getContactLoading(state),
  contactList: getContactList(state),
  contactData: getContactData(state)
});

const mapDispatchToProps = dispatch => ({
  pageUpdateConnect: params => {
    dispatch(pageUpdate(params));
  },
  contactSingleRequestConnect: params => {
    dispatch(contactSingleRequest(params));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact);
