import React, { Component, Fragment } from 'react';
import Dropdown from 'react-dropdown';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import Axios from 'axios';
import { withAuth } from '../../services/authServices';

import SideNav from '../../components/SideNav';
import TopNav from '../../components/TopNav';
import Table from '../../components/Table';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import {
  getApplicationList,
  getApplicationData,
  getApplicationRequest,
  getApplicationPagination,
  getApplicationCountByStatus
} from '../../selectors/applicationSelectors';

import ApplicationEmailForm from './ApplicationEmailForm';
// import { ApplicationList } from './ApplicationList';
import { applicationRequest, applicationCountByStatusRequest } from '../../actions/applicationActions';
import { pageUpdate } from '../../actions/settingsActions';
import Pagination from '../../components/Pagination';
import RefreshButton from '../../components/RefreshButton';
import SearchBox from '../../components/SearchBox';
import { counter } from '@fortawesome/fontawesome-svg-core';

const API = Axios.create({ baseURL: process.env.REACT_APP_APIURL });

const options = [
  { value: 'pending', label: 'Pending' },
  { value: 'shortlisted', label: 'Shortlisted' },
  { value: 'contacted', label: 'Contacted' },
  { value: 'interview-scheduled', label: 'Interview Scheduled' },
  { value: 'interviewed', label: 'Interviewed' },
  { value: 'hired', label: 'Hired' },
  { value: 'waiting', label: 'Waiting' },
  { value: 'rejected', label: 'Rejected' }
];

class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailModal: false,
      selectedApplicationId: undefined,
      emailSuccessModal: false,
      orderBy: '',
      orderAsc: false,
      search: '',
      filteredBy: { value: '', label: 'All' },
      tabIndex: 0,
      page: 1
    };
    this.toggleEmailModal = this.toggleEmailModal.bind(this);
    this.toggleEmailSuccessModal = this.toggleEmailSuccessModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fetchApplication = this.fetchApplication.bind(this);
    this.order = this.order.bind(this);
    this.fetchApplicationDebounced = debounce(this.fetchApplication, 500);
  }

  componentDidMount() {
    const { pageUpdateConnect } = this.props;
    pageUpdateConnect('Application');
    this.fetchApplication();
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { careerId = undefined }
      }
    } = this.props;

    if (prevProps.match.params.careerId !== careerId) {
      this.fetchApplication();
    }
  }

  handleChange(event) {
    const {
      target: { name, type, value, checked }
    } = event;
    // console.log(event.target.value);

    const fieldValue = type === 'checkbox' ? checked : value;

    this.setState({ [name]: fieldValue }, () => {
      if (name === 'search') {
        this.fetchApplicationDebounced();
      }
    });
  }

  fetchApplication(page = 1) {
    this.setState({ page });
    const {
      match: {
        params: { careerId = undefined }
      },
      applicationPagination,
      applicationRequestConnect,
      applicationCountByStatusConnect
    } = this.props;

    const { search, orderBy, orderAsc, filteredBy } = this.state;

    const params = {
      career_post: careerId,
      search,
      pageSize: applicationPagination.limit,
      page,
      orderBy,
      orderDir: orderAsc ? 'ASC' : 'DESC',
      status: filteredBy.value
    };

    applicationRequestConnect(params);
    applicationCountByStatusConnect({career_post: careerId});
    // ApplicationApi.getApplication(params);
    // ApplicationApi.getApplication(params).then(res => {
    //   if (res.data.career) {
    //     pageUpdateConnect(`Applications: ${res.data.career.title}`);
    //   }
    // });
  }

  toggleEmailModal(applicationId) {
    const { emailModal, selectedApplicationId } = this.state;
    const { applicationData } = this.props;

    this.setState({
      emailModal: !emailModal,
      selectedApplicationId:
        applicationId in applicationData ? applicationId : selectedApplicationId
    });
  }

  toggleEmailSuccessModal() {
    this.setState(state => {
      return { emailSuccessModal: !state.emailSuccessModal };
    });
  }

  updateStatus = (id, status) => {
    API.patch(
      `application/applicant/${id}/ `,
      { status: status.value },
      { headers: withAuth({ 'Content-Type': 'application/json' }) }
    )
      .then(res => {
        this.fetchApplication();
      })
      .catch(err => {
      });

    // Todo
    // this.props.updateStatus(id);
  };

  order(orderBy, orderAsc = true) {
    this.setState(
      {
        orderBy,
        orderAsc
      },
      this.fetchApplication
    );
  }

  filterList = tabIndex => {
    let status = { value: '', label: 'All' };
    if (tabIndex !== 0) {
      status = options[tabIndex - 1];
    }
    this.setState({ tabIndex: tabIndex, filteredBy: status }, () => {
      this.fetchApplication();
    });
  };

  render() {
    const {
      // authenticated,
      isApplicationLoading,
      applicationList,
      applicationData,
      applicationPagination,
      applicationCountByStatus,
      match
    } = this.props;

    const {
      selectedApplicationId,
      emailModal,
      emailSuccessModal,
      search
    } = this.state;


    const columns = [{
      Header: '',
      valueType: 'index'
    },
      {
        Header: 'Name',
        accessor: '',
        Cell: application => (
          <Fragment>
            <Link
              to={`/application/applicant/${
                application.id
              }/${application.name.replace(' ', '-')}`}
              className="font-weight-bold"
            >
              {application.name}
            </Link>
            <span className="text-muted small d-block">{application.email}</span>
          </Fragment>
        ),
        orderField: 'name'
      },
      {
        Header: 'Post',
        Cell: application => (
          <Link to={`/application/${application.career_post}`}>
            {application.career_post_title}
          </Link>
        ),
        orderField: 'career_post'
      },
      {
        Header: 'Phone',
        Cell: application => <span className="text-muted">{application.phone}</span>
      },
      {
        Header: 'Note',
        Cell: application => <span className="text-muted">{application.notes}</span>,
        className: 'td--truncate-multi-line',
        width: '140px'
      },
      {
        Header: 'Submitted',
        Cell: application => (
          <span className="text-muted">
          {moment.unix(application.created).calendar(null, { sameElse: 'MMMM D, YYYY' })}
        </span>
        ),
        orderField: 'created'
      },
      {
        Header: 'Status',
        Cell: application => {
          return (
            <Dropdown
              options={options}
              onChange={val => {
                this.updateStatus(application.id, val);
              }}
              value={application.status}
              placeholder="Select an option"
            />
          );
        }
      },
      {
        Header: '',
        Cell: application => (
          <button
            type="button"
            className="btn btn-light btn-sm"
            onClick={() => this.toggleEmailModal(application.id)}
          >
            <FontAwesomeIcon icon={faEnvelope}/>
          </button>
        ),
        className: 'text-right td-hover'
      }
    ];

    const data = applicationList.map(id => applicationData[id]);

    let pendingCount = applicationCountByStatus.find(statusCount => statusCount.status === 'pending');
    if (pendingCount) pendingCount = pendingCount.count; else pendingCount = 0;

    let shortlistedCount = applicationCountByStatus.find(statusCount => statusCount.status === 'shortlisted');
    if(shortlistedCount) shortlistedCount = shortlistedCount.count; else shortlistedCount = 0;

    let contactedCount = applicationCountByStatus.find(statusCount => statusCount.status === 'contacted');
    if(contactedCount) contactedCount = contactedCount.count; else contactedCount = 0;

    let interviewScheduledCount = applicationCountByStatus.find(statusCount => statusCount.status === 'interview-scheduled');
    if(interviewScheduledCount) interviewScheduledCount = interviewScheduledCount.count; else interviewScheduledCount = 0;

    let interviewedCount = applicationCountByStatus.find(statusCount => statusCount.status === 'interviewed');
    if(interviewedCount) interviewedCount = interviewedCount.count; else interviewedCount = 0;

    let hiredCount = applicationCountByStatus.find(statusCount => statusCount.status === 'hired');
    if(hiredCount) hiredCount = hiredCount.count; else hiredCount = 0;

    let waitingCount = applicationCountByStatus.find(statusCount => statusCount.status === 'waiting');
    if(waitingCount) waitingCount = waitingCount.count; else waitingCount = 0;

    let rejectedCount = applicationCountByStatus.find(statusCount => statusCount.status === 'rejected');
    if(rejectedCount) rejectedCount = rejectedCount.count; else rejectedCount = 0;

    return (
      <div className="page-career">
        <SideNav/>
        <TopNav/>
        <div className="content">
          <div className="page-nav d-flex align-items-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col-5 text-right">
                  <SearchBox
                    onChange={this.handleChange}
                    value={search}
                    name="search"
                    placeholder="Search Applicants"
                  />
                  {/* <input
                    type="text"
                    className="form-control page-nav__search"
                    placeholder="Search Applicants"
                    onChange={this.handleChange}
                    name="search"
                    value={search}
                  /> */}
                </div>
                <div className="col-3">
                  <Pagination
                    page={applicationPagination.page}
                    pageSize={applicationPagination.pageSize}
                    total={applicationPagination.total}
                    onPageChange={this.fetchApplication}
                  />
                </div>
                {/*<div className="col-3">*/}
                {/*  <Select*/}
                {/*    // eslint-disable-next-line react/destructuring-assignment*/}
                {/*    value={this.state.filteredBy}*/}
                {/*    onChange={this.filterList}*/}
                {/*    options={[{ value: '', label: 'All' }, ...options]}*/}
                {/*  />*/}
                {/*</div>*/}
                {/*<div className="col-1">*/}
                {/*  <RefreshButton*/}
                {/*    isRefreshing={isApplicationLoading}*/}
                {/*    className="mr-4"*/}
                {/*    onClick={() => {*/}
                {/*      this.fetchApplication(applicationPagination.page);*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</div>*/}
              </div>
            </div>
          </div>

          <div className="content-inner">
            <div className="row">
              <div className="col">
                {isApplicationLoading ? null :
                  <Tabs
                    style={{ color: '#6c757d'}}
                    selectedIndex={this.state.tabIndex}
                    onSelect={this.filterList}
                  >
                    <TabList>
                      <Tab>All
                        ({pendingCount+shortlistedCount+contactedCount+interviewScheduledCount+interviewedCount+hiredCount+waitingCount+rejectedCount})</Tab>
                      <Tab>Pending ({pendingCount})</Tab>
                      <Tab>Shortlisted ({shortlistedCount})</Tab>
                      <Tab>Contacted ({contactedCount})</Tab>
                      <Tab>Interview Scheduled ({interviewScheduledCount})</Tab>
                      <Tab>Interviewed ({interviewedCount})</Tab>
                      <Tab>Hired ({hiredCount})</Tab>
                      <Tab>Waiting ({waitingCount})</Tab>
                      <Tab>Rejected ({rejectedCount})</Tab>
                    </TabList>
                  </Tabs>}
                <div
                  className={`card card--dash ${
                    isApplicationLoading ? 'card--loading' : null
                  }`}
                >
                  <Table columns={columns} data={data} order={this.order} page={this.state.page}
                         pageSize={this.props.applicationPagination.pageSize}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        {applicationData[selectedApplicationId] && (
          <Modal isOpen={emailModal} toggle={this.toggleEmailModal} centered>
            <button
              type="button"
              className="close"
              style={{
                position: 'absolute',
                top: '15px',
                right: '15px',
                zIndex: 2
              }}
              onClick={this.toggleEmailModal}
            >
              &times;
            </button>

            <ModalBody className="p-5">
              <h4 className="font-weight-bold mb-4">Quick Email</h4>
              <div className="form-group">
                <span className="label">To: </span>
                <span className="font-weight-bold">
                  {applicationData[selectedApplicationId].name}
                </span>
              </div>
              <ApplicationEmailForm
                application={applicationData[selectedApplicationId]}
                onClose={this.toggleEmailModal}
                onSuccess={this.toggleEmailSuccessModal}
              />
            </ModalBody>
          </Modal>
        )}

        {applicationData[selectedApplicationId] && (
          <Modal
            isOpen={emailSuccessModal}
            toggle={this.toggleEmailSuccessModal}
            centered
          >
            <button
              type="button"
              className="close"
              style={{
                position: 'absolute',
                top: '15px',
                right: '15px',
                zIndex: 2
              }}
              onClick={this.toggleEmailSuccessModal}
            >
              &times;
            </button>
            <ModalBody className="p-5">
              <h4 className="font-weight-bold mb-4">Email Sent</h4>
              <p>
                <span>Email was sent successfully to</span>
                <b>{applicationData[selectedApplicationId].name}</b>
                <span>.</span>
              </p>
              <div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.toggleEmailSuccessModal}
                >
                  Okay
                </button>
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
    );
  }
}

Application.propTypes = {
  // pageUpdateConnect: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      careerId: PropTypes.string
    }).isRequired
  }).isRequired,
  applicationData: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  isApplicationLoading: PropTypes.bool,
  applicationRequestConnect: PropTypes.func.isRequired,
  applicationList: PropTypes.arrayOf(PropTypes.number).isRequired,
  applicationPagination: PropTypes.shape({
    total: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired
  }).isRequired,
  applicationCountByStatusConnect: PropTypes.func.isRequired,
  applicationCountByStatus: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired
  })).isRequired
};

Application.defaultProps = {
  isApplicationLoading: false
};

const mapStateToProps = state => ({
  applicationList: getApplicationList(state),
  applicationData: getApplicationData(state),
  isApplicationLoading: getApplicationRequest(state),
  applicationPagination: getApplicationPagination(state),
  applicationCountByStatus: getApplicationCountByStatus(state)
});

const mapDispatchToProps = dispatch => ({
  applicationRequestConnect: params => {
    dispatch(applicationRequest(params));
  },
  pageUpdateConnect: page => {
    dispatch(pageUpdate(page));
  },
  applicationCountByStatusConnect: params => {
    dispatch(applicationCountByStatusRequest(params));
  }

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Application);
