import * as settings from '../actions/settingsActions'

const initialState = {
  sideNavCollapse: false,
  pageTitle: ""
}

export default (state=initialState, action) => {
  switch(action.type) {
    case settings.SIDENAV_COLLAPSE:
      return {
        ...state,
        sideNavCollapse: true
      }

    case settings.SIDENAV_EXPAND:
      return {
        ...state,
        sideNavCollapse: false
      }
    case settings.PAGE_UPDATE:
      return {
        ...state,
        pageTitle: action.pageTitle
      }
    default:
      return state
  }
}

export function isSideNavCollapsed(state) {
  return state.sideNavCollapse
}
  
export function isSideNavExpanded(state) {
  return !isSideNavCollapsed(state)
}
  
export function pageTitle(state) {
  return state.pageTitle
}
