import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faUsers } from '@fortawesome/free-solid-svg-icons';

import { isAuthenticated } from '../../selectors/authSelectors';
import SideNav from '../../components/SideNav';
import TopNav from '../../components/TopNav';
import { pageUpdate } from '../../actions/settingsActions';
import { withAuth } from '../../services/authServices';
import { careersSuccess, careersDelete, careersRequest } from '../../actions/careersActions';
import {
  getCareersList,
  getCareersData
} from '../../selectors/careersSelectors';

const API = Axios.create({ baseURL: process.env.REACT_APP_APIURL });

class CareerList extends Component {
  constructor(props) {
    super(props);
    this.onDelete = this.onDelete.bind(this);
  }

  onDelete() {
    if (window.confirm('Are you sure you want to delete?')) {
      API.delete(`career/${this.props.career.id}/`, {
        headers: withAuth({ 'Content-Type': 'application/json' })
      })
        .then(res => {
          this.props.onDelete(this.props.career.id);
        })
        .catch(error => {
          console.log(error.response);
        });
    }
  }

  render() {
    return (
      <tr>
        <td className="align-middle text-muted">{this.props.count + 1}</td>
        <td className="align-middle">{this.props.career.title}</td>
        <td className="align-middle">{this.props.career.location}</td>
        <td className="align-middle">{this.props.career.created_readable}</td>
        <td className="align-middle">{this.props.career.deadline_readable}</td>
        <td className="align-middle">
          {this.props.career.expired ? (
            <span className="badge badge-danger">Expired</span>
          ) : this.props.career.active ? (
            <span className="badge badge-success">Active</span>
          ) : (
            <span className="badge badge-info">Inactive</span>
          )}
        </td>
        <td className="align-middle text-right td-hover">
          <Link
            to={`/career/${this.props.career.id}/edit`}
            className="btn btn-sm btn-light"
            title="Edit"
          >
            <FontAwesomeIcon icon={faEdit} />
          </Link>
          <Link
            to={`/application/${this.props.career.id}`}
            className="btn btn-sm btn-light"
            title="Applications"
          >
            <FontAwesomeIcon icon={faUsers} />
          </Link>
          <button
            className="btn btn-light btn-sm"
            onClick={this.onDelete}
            title="Delete"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </td>
      </tr>
    );
  }
}

class Careers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: 'Careers'
    };
  }

  componentDidMount() {
    this.props.careersRequestConnect({
      type: 'all'
    });
    // API.get('career/', {
    //   params: {
    //     type: 'all'
    //   },
    //   headers: withAuth({ 'Content-Type': 'application/json' })
    // })
    //   .then(res => {
    //     this.props.careersSuccess(res.data);
    //   })
    //   .catch(error => {
    //     this.props.careersFailure(error.response);
    //   });
    // this.props.pageUpdate(this.state.pageTitle);
  }

  render() {
    return (
      <div className="page-careers">
        <SideNav />
        <TopNav />
        <div className="content">
          <div className="page-nav d-flex align-items-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col text-right">
                  <Link to="/career/new" className="btn btn-primary">
                    <FontAwesomeIcon icon={faPlus} transform="shrink-5" /> New
                    Career
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="content-inner">
            <div className="row">
              <div className="col">
                <div className="card card--dash">
                  <div className="">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th>Title</th>
                          <th>Location</th>
                          <th>Posted on</th>
                          <th>Deadline</th>
                          <th>Status</th>
                          <th className="text-right">...</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.careersList &&
                          this.props.careersList.map((career_id, idx) => (
                            <CareerList
                              key={idx}
                              career={this.props.careersData[career_id]}
                              count={idx}
                              onDelete={this.props.careersDelete}
                            />
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticated(state),
  careersList: getCareersList(state),
  careersData: getCareersData(state)
});

const mapDispatchToProps = dispatch => ({
  careersRequestConnect: (params) => {
    dispatch(careersRequest(params))
  },

  pageUpdate: page => {
    dispatch(pageUpdate(page));
  },
  careersSuccess: careers => {
    console.log(careers);

    dispatch(careersSuccess(careers));
    // careers.forEach(career => {
    //   dispatch(careersSuccess(career));
    // });
  },
  careersFailure: res => {
    console.log(res);
  },
  careersDelete: id => {
    dispatch(careersDelete(id));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Careers);
