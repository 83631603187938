import React, { Component } from 'react';
import { connect } from 'react-redux'

import { isAuthenticated } from '../../selectors/authSelectors'
import { Redirect, Link } from 'react-router-dom';
import SideNav from '../../components/SideNav';
import TopNav from '../../components/TopNav';
import { pageUpdate } from '../../actions/settingsActions';
import Axios from 'axios';
import { withAuth } from '../../services/authServices';
import { applicationSingleSuccess } from '../../actions/applicationActions';
import { getApplicationList, getApplicationData } from '../../selectors/applicationSelectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ApplicationForm from './ApplicationForm';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { careersSingleSuccess } from '../../actions/careersActions';

const API = Axios.create({ baseURL: process.env.REACT_APP_APIURL });

class ApplicationEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Applicant",
      selectedApplicationId: undefined,
      application_id: props.match.params.application_id,
    }
    this.onSubmit = this.onSubmit.bind(this);

  }

  componentDidMount() {
    const application_id = this.props.match.params.application_id;

    this.props.pageUpdate(this.state.pageTitle);

    // TODO: move this to api
    API.get(`application/applicant/${application_id}/`, {
      headers: withAuth({ 'Content-Type': 'application/json' })
    })
      .then((res) => {
        if (res.data) {
          this.props.applicationSingleSuccess(res.data);
          this.props.pageUpdate(`Applicant: ${res.data.name}`);
        }
      })
      .catch((error) => {
        this.props.applicationFailure(error.response);
      });
  }

  logout() {
    this.props.logout();
  }

  toggleEmailModal(applicationId) {
    let selectedApplicationId = this.state.selectedApplicationId;
    if (applicationId in this.props.applicationData) {
      console.log(applicationId);
      selectedApplicationId = applicationId;
      console.log(this.props.applicationData[applicationId]);
    } else {
      // selectedApplicationId = undefined;
    }
    this.setState({
      emailModal: !this.state.emailModal,
      selectedApplicationId: selectedApplicationId
    })
  }

  toggleEmailSuccessModal() {
    this.setState({
      emailSuccessModal: !this.state.emailSuccessModal,
    })
  }

  onSubmit() {
    this.props.history.push('/application');
  }

  render() {
    if (!this.props.isAuthenticated) {
      return (
        <Redirect to='/login' />
      )
    } else {
      return <div className="page-careers">
        <SideNav />
        <TopNav />
        <div className="content">
          <div className="page-nav d-flex align-items-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col text-left">
                  <Link to="/application" className="btn btn-light">
                    <FontAwesomeIcon icon={faArrowLeft} transform="shrink-5" /> Back to Application
                  </Link>
                </div>

                <div className="col text-right">
                  {/* <Link to="/careers/new" className="btn btn-primary">
                    <FontAwesomeIcon icon={faPlus} transform="shrink-5"/> New Career
                  </Link> */}
                </div>
              </div>
            </div>
          </div>

          <div className="content-inner">
            <div className="row">
              <div className="col">
                {this.props.applicationData[this.state.application_id] ?
                  <div className="card card--dash">
                    <div className="card-body">
                      {<ApplicationForm onSubmit={this.onSubmit} application={this.props.applicationData[this.state.application_id]} />}
                    </div>
                  </div> : "Loading"
                }

              </div>
            </div>
          </div>
        </div>

      </div>
    }
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  applicationList: getApplicationList(state),
  applicationData: getApplicationData(state),
})

const mapDispatchToProps = (dispatch) => ({
  pageUpdate: (page) => {
    dispatch(pageUpdate(page))
  },
  careersSingleSuccess: (careers) => {
    dispatch(careersSingleSuccess(careers));
  },
  applicationSingleSuccess: (application) => {
    dispatch(applicationSingleSuccess(application));
  },
  applicationFailure: (res) => {
    console.log(res);
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationEdit);
