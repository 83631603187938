import API from './api';

class CareersApi {
  static getCareers({ type }, config) {
    const params = {
      type
    };

    return API.GET('careers/', params, config);
  }
}

export default CareersApi;
