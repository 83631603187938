import React, { Component } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux'

import { loginRequest, loginFailure, loginSuccess } from '../../actions/authActions'

import { isAuthenticated, getAuthErrors } from '../../selectors/authSelectors'

import { Redirect } from 'react-router-dom';
import logo from '../../assets/img/iw-logo.svg';

class Login extends Component {

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />
    }

    return <div className="section section--full-height d-flex align-items-center bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4">
            <div className="card card--shadow-lg">
              <div className="card-body">
                <h5 className="card-title">
                  <div className="text-center">
                    <img src={logo} alt="logo" className="w-80" />
                  </div>
                </h5>

                <Formik
                  // loginSuccess={this.props.loginSuccess}
                  // loginRequest={this.props.loginRequest}
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  onSubmit={(
                    values,
                    actions,
                    // { setSubmitting, setErrors /* setValues and other goodies */ }
                  ) => {
                    this.props.loginRequest(values, actions);
                    // setSubmitting(false);
                    return;
                    // AuthApi.getAccessToken(values).then(res => {
                    //   console.log(res);
                    // }).catch(err => {
                    //   console.log(err);
                    // })
                    // setSubmitting(false);
                    // return;
                    // API.post('auth/token/obtain/', values, { headers: { 'Content-Type': 'application/json' } })
                    // .then((res)=> {
                    //   setSubmitting(false);
                    //   this.props.loginSuccess(res.data);
                    // })
                    // .catch((error)=> {
                    //   const err= {};
                    //   if (error.response) {
                    //     if (error.response.data) {
                    //       Object.entries(error.response.data).forEach((obj) => {
                    //         err[obj[0]] = obj[1][0]
                    //       });
                    //       setErrors(err);
                    //     }
                    //     this.props.loginFailure(error.response);
                    //   } else {
                    //     this.props.loginFailure({
                    //       data: {'non_field_errors': error.message},
                    //     });
                    //     err['non_field_errors'] = error.message
                    //     setErrors(err);
                    //   }
                    //   setSubmitting(false);
                    // });        

                  }}
                  render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label htmlFor="txtEmail">Email</label>
                          <input
                            id="txtEmail"
                            className="form-control"
                            type="text"
                            name="email"
                            onChange={handleChange}
                            // onBlur={handleBlur}
                            value={values.email}
                          />
                          {touched.email && errors.email && <div className="invalid-feedback d-block">{errors.email}</div>}
                        </div>
                        <div className="form-group">
                          <label htmlFor="txtPassword">Password</label>
                          <input
                            id="txtPassword"
                            className="form-control"
                            type="password"
                            name="password"
                            onChange={handleChange}
                            // onBlur={handleBlur}
                            value={values.password}
                          />
                          {touched.password && errors.password && <div className="invalid-feedback d-block">{errors.password}</div>}
                        </div>
                        <div className="form-group">
                          <div className="invalid-feedback d-block">{errors.non_field_errors}</div>
                        </div>
                        <div>
                          <button className="btn btn-primary btn-block text-uppercase font-weight-bold" type="submit" disabled={isSubmitting}>
                            {isSubmitting ? "Logging in" : "Login"}
                          </button>
                        </div>
                      </form>
                    )}
                />



              </div>
            </div>


          </div>
        </div>
      </div>





    </div>
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  authErrors: getAuthErrors(state)
})

const mapDispatchToProps = (dispatch) => ({
  loginRequest: (payload, formikActions) => {
    dispatch(loginRequest(payload, formikActions));
  },
  loginSuccess: (payload) => {
    dispatch(loginSuccess(payload))
  },
  loginFailure: (payload) => {
    dispatch(loginFailure(payload))
  },

})

export default connect(mapStateToProps, mapDispatchToProps)(Login);
