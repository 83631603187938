import {
  CONTACT_REQUEST,
  CONTACT_SUCCESS,
  CONTACT_FAILURE,
  CONTACT_SINGLE_REQUEST,
  CONTACT_SINGLE_SUCCESS,
  CONTACT_SINGLE_FAILURE,
} from '../actions/contactActions';

const paginationLimit = parseInt(process.env.REACT_APP_PAGINATION_SIZE, 10);

const initialState = {
  loading: false,
  data: {},
  list: [],
  pagination: {
    total: 0,
    pageSize: paginationLimit,
    page: 1
  },
  errors: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case CONTACT_SUCCESS: {
      const list = [];
      const data = {};
      action.payload.results.forEach(contact => {
        list.push(contact.id);
        data[contact.id] = contact;
      });

      return {
        ...state,
        data: {
          ...state.data,
          ...data
        },
        list,
        pagination: {
          total: action.payload.count,
          pageSize: action.params.pageSize ? action.params.pageSize : paginationLimit,
          page: action.params.page ? action.params.page : 1
        },
        loading: false,
        errors: {}
      };
    }
    case CONTACT_FAILURE: {
      return {
        ...state,
        errors: action.payload.data || {
          non_field_errors: action.payload.statusText
        },
        loading: false
      };
    }

    case CONTACT_SINGLE_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }

    case CONTACT_SINGLE_SUCCESS: {
      const contact = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [contact.id]: contact
        },
        errors: {},
        loading: false
      };
    }
    case CONTACT_SINGLE_FAILURE: {
      return {
        ...state,
        errors: {
          ...action.payload
        },
        loading: false
      };
    }

    default:
      return state;
  }
};

export function getContact(state) {
  return contactId => {
    return state.data[contactId];
  };
}

export function getContactList(state) {
  return state.list;
}

export function getContactData(state) {
  return state.data;
}

export function getContactLoading(state) {
  return state.loading;
}

export function getContactPagination(state) {
  return state.pagination;
}
