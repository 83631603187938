import * as application from '../actions/applicationActions';

const paginationLimit = parseInt(process.env.REACT_APP_PAGINATION_SIZE, 10);

const initialState = {
  applicationRequest: false,
  applicationData: {},
  applicationList: [],
  applicationPagination: {
    total: 0,
    pageSize: paginationLimit,
    page: 1
  },
  applicationCountByStatus: [],
  applicationErrors: {},
  applicationSingleErrors: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case application.APPLICATION_REQUEST: {
      return {
        ...state,
        applicationRequest: true
      };
    }

    case application.APPLICATION_COUNT_BY_STATUS_REQUEST: {
      return {
        ...state,
        applicationStatusCountRequest: true
      }
    }

    case application.APPLICATION_COUNT_BY_STATUS_SUCCESS: {
      return {
        ...state,
        applicationCountByStatus: action.payload,
        applicationStatusCountRequest: false
      }
    }

    case application.APPLICATION_COUNT_BY_STATUS_FAILURE: {
      return {
        ...state,
        applicationStatusCountRequest: false
      }
    }

    case application.APPLICATION_SUCCESS: {
      const applicationList = [];
      const applicationData = {};
      action.payload.results.forEach(career => {
        applicationList.push(career.id);
        applicationData[career.id] = career;
      });

      return {
        ...state,
        applicationData: {
          ...state.applicationData,
          ...applicationData
        },
        applicationList,
        applicationPagination: {
          total: action.payload.count,
          pageSize: action.params.pageSize ? action.params.pageSize : paginationLimit,
          page: action.params.page ? action.params.page : 1
        },
        applicationRequest: false
      };
    }


    case application.APPLICATION_FAILURE: {
      return {
        ...state,
        applicationErrors: action.payload.data || {
          non_field_errors: action.payload.statusText
        },
        applicationRequest: false
      };
    }
    case application.APPLICATION_DELETE: {
      return {
        ...state,
        applicationList: state.applicationList.filter(id => {
          return id !== action.id;
        })
      };
    }
    case application.APPLICATION_SINGLE_SUCCESS: {
      return {
        ...state,
        applicationData: {
          ...state.applicationData,
          [action.payload.id]: action.payload
        }
      };
    }
    case application.APPLICATION_SINGLE_FAILURE: {
      return {
        ...state,
        applicationSingleErrors: {
          error: 'error' // TODO
        }
      };
    }
    default:
      return state;
  }
};

export function getApplication(state) {
  return applicationId => {
    return state.applicationData[applicationId];
  };
}

export function getApplicationList(state) {
  return state.applicationList;
}

export function getApplicationData(state) {
  return state.applicationData;
}

export function getApplicationRequest(state) {
  return state.applicationRequest;
}

export function getApplicationPagination(state) {
  return state.applicationPagination;
}

export function getApplicationCountByStatus(state) {
  return state.applicationCountByStatus;
}
