import React, { Component } from 'react';
import { connect } from 'react-redux'

import { isAuthenticated } from '../../selectors/authSelectors'
import { Redirect, Link } from 'react-router-dom';
import SideNav from '../../components/SideNav';
import TopNav from '../../components/TopNav';
import { pageUpdate } from '../../actions/settingsActions';
import Axios from 'axios';
import { withAuth } from '../../services/authServices';
import { blogSuccess, blogDelete } from '../../actions/blogActions';
import { getBlogList, getBlogData } from '../../selectors/blogSelectors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';

const API = Axios.create({ baseURL: process.env.REACT_APP_APIURL });


class BlogList extends Component {

  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onEdit() {
    console.log(this.props.blog, "edit");
  }

  onDelete() {
    if (window.confirm(`Are you sure you want to delete ${this.props.blog.title}?`)) {
      API.delete(`blog/${this.props.blog.id}/`, { 
        headers: withAuth({ 'Content-Type': 'application/json' }) })
      .then((res)=> {
        this.props.onDelete(this.props.blog.id);
      })
      .catch((error)=> {
        console.log(error.response);
      });
    }
  }

  render() {
    return <tr>
      <td className="align-middle text-muted">{this.props.count + 1}</td>
      <td className="align-middle">{this.props.blog.title}</td>
      <td className="align-middle text-muted">{moment.unix(this.props.blog.created).calendar(null, {sameElse: 'MMMM D, YYYY'})}</td>
      {/* <td className="align-middle">{this.props.blog.updated}</td> */}
      <td className="align-middle">{this.props.blog.published ? <span className="badge badge-success">Published</span> : <span className="badge badge-info">Not Published</span>}</td>
      <td className="align-middle text-right td-hover">
        <Link to={`/blog/${this.props.blog.id}/edit`} className="btn btn-sm btn-light" title="Edit">
          <FontAwesomeIcon icon={faEdit} />
        </Link>
        <button className="btn btn-light btn-sm" onClick={this.onDelete} title="Delete">
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      </td>
    </tr>
  }
}


class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "Blog"
    }
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    API.get('blog/', { 
      params:{
        type: "all"
      }, 
      headers: withAuth({ 'Content-Type': 'application/json' }) })
    .then((res)=> {
      this.props.blogSuccess(res.data);
    })
    .catch((error)=> {
      this.props.blogFailure(error.response);
    });
    this.props.pageUpdate(this.state.pageTitle);
  }

  logout() {
    this.props.logout();
  }


  render() {
    if(!this.props.isAuthenticated) {
      return (
        <Redirect to='/login' />
      )
    } else {
      return <div className="page-blog">
        <SideNav/>
        <TopNav/>
        <div className="content">
          <div className="page-nav d-flex align-items-center">
            <div className="container-fluid">
              <div className="row">
                <div className="col text-right">
                  <Link to="/blog/new" className="btn btn-primary">
                    <FontAwesomeIcon icon={faPlus} transform="shrink-5"/> New Blog
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="content-inner">
            <div className="row">
              <div className="col">

                <div className="card card--dash">
                  <div className="">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>SN</th>
                          <th>Title</th>
                          <th>Posted on</th>
                          <th>Status</th>
                          <th className="text-right">...</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.blogList && this.props.blogList.map((blog_id, idx) => 
                          <BlogList key={idx} blog={this.props.blogData[blog_id]} count={idx} onDelete={this.props.blogDelete} onEdit={this.onBlogEdit}/>
                        )}

                      </tbody>
                    </table>
                  
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state),
  blogList: getBlogList(state),
  blogData: getBlogData(state),
})

const mapDispatchToProps = (dispatch) => ({
  pageUpdate: (page) => {
    dispatch(pageUpdate(page))
  },
  blogSuccess: (blog) => {
    dispatch(blogSuccess(blog));
  },
  blogFailure: (res) => {
    console.log(res);
  },
  blogDelete: (id) => {
    dispatch(blogDelete(id));
  }

})

export default connect(mapStateToProps, mapDispatchToProps)(Blog);