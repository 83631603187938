import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBox = ({onChange, value, placeholder, name}) => {

  return (
    <div className="searchbox">
      <input
        type="text"
        className="form-control searchbox__input"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        name={name}
      />
      <FontAwesomeIcon icon={faSearch} className="searchbox__icon" />
    </div>
  );
};

export default SearchBox;
