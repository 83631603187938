/*
 * action types
 */

export const LOGIN_REQUEST = '@@auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = '@@auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = '@@auth/LOGIN_FAILURE';
export const TOKEN_REQUEST = '@@auth/TOKEN_REQUEST';
export const TOKEN_RECEIVED = '@@auth/TOKEN_RECEIVED';
export const TOKEN_FAILURE = '@@auth/TOKEN_FAILURE';
export const LOGOUT = '@@auth/LOGOUT';

export const RESUME_AUTH_REQUEST = '@@auth/RESUME_AUTH_REQUEST';

/*
 * action creators
 */

export function loginRequest(payload, formikActions) {
  return { type: LOGIN_REQUEST, payload, formikActions };
}

export function loginSuccess(payload) {
  return { type: LOGIN_SUCCESS, payload };
}

export function loginFailure(payload) {
  return { type: LOGIN_FAILURE, payload };
}

export function logout() {
  return { type: LOGOUT };
}

export function refreshAccessToken(payload) {
  return { type: TOKEN_REQUEST, payload };
}

export function refreshAccessTokenSuccess(payload) {
  return { type: TOKEN_RECEIVED, payload };
}

export function resumeAuthRequest() {
  return { type: RESUME_AUTH_REQUEST };
}
