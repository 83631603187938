import React, { Component } from 'react';
import { connect } from 'react-redux'
import { isSideNavCollapsed, pageTitle } from '../selectors/settingsSelectors';
import { logout } from '../actions/authActions';

class TopNav extends Component {
  render() {
    return <div className="top-nav d-flex align-items-center">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col">
            <h1 className="mb-0">{this.props.pageTitle}</h1>
          </div>
          <div className="col text-right">
            <button className="btn btn-link" onClick={this.props.logout}>Logout</button>
          </div>
        </div>
      </div>
    </div>
  }
};



const mapStateToProps = (state) => ({
  sideNavCollapsed: isSideNavCollapsed(state),
  pageTitle: pageTitle(state),
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logout());
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);