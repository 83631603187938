import Axios from 'axios';

// import { getRefreshToken } from '../selectors/authSelectors';

class API {
  static instance = Axios.create({ baseURL: process.env.REACT_APP_APIURL });

  static GET(path, params, config) {
    return this.instance
      .get(path, { params, ...config })
      .then(res => res)
      .catch(err => {
        throw err;
      });

    // return new Promise((resolve, reject) => {
    //   this.instance.get(path, {
    //     params: params,
    //     headers: headers,
    //   }).then((res)=> {
    //     resolve(res);
    //   }).catch((error)=> {
    //     // if (error.response.status === 401 && 'Authorization' in headers) {
    //     //   // request access token from refresh token.
    //     //   console.log('request access token.');
    //     //   AuthApi.refreshAccessToken().then((data) => {
    //     //     console.log("here data access", data);
    //     //   }).catch((error) => {
    //     //     console.log("here data access2", error);
    //     //   });
    //     // }
    //     reject(error);

    //   });
    // });
  }

  static POST(path, data = {}, config = {}) {
    return this.instance.post(path, data, config);
    // return new Promise((resolve, reject) => {
    //   this.instance.post(path, data, config).then((res)=> {
    //     resolve(res);

    //   }).catch((error)=> {
    //     // if (error.response.status === 401 && 'Authorization' in headers) {
    //     //   // request access token from refresh token.
    //     //   console.log('request access token.');
    //     // }
    //     reject(error);
    //   });
    // });
  }

  static PUT(path, data = {}, config = {}) {
    return this.instance.put(path, data, config);
  }

  static PATCH(path, data = {}, config = {}) {
    return this.instance.patch(path, data, config);
  }
}

export default API;
