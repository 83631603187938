/*
 * action types
 */
export const CONTACT_REQUEST = '@@contact/CONTACT_REQUEST';
export const CONTACT_SUCCESS = '@@contact/CONTACT_SUCCESS';
export const CONTACT_FAILURE = '@@contact/CONTACT_FAILURE';

export const CONTACT_SINGLE_REQUEST = '@@contact/CONTACT_SINGLE_REQUEST';
export const CONTACT_SINGLE_SUCCESS = '@@contact/CONTACT_SINGLE_SUCCESS';
export const CONTACT_SINGLE_FAILURE = '@@contact/CONTACT_SINGLE_FAILURE';

/*
 * action creators
 */
export function contactRequest(payload) {
  return { type: CONTACT_REQUEST, payload };
}

export function contactSuccess(payload, params) {
  return { type: CONTACT_SUCCESS, payload, params };
}

export function contactFailure(payload) {
  return { type: CONTACT_FAILURE, payload };
}

export function contactSingleRequest(payload) {
  return { type: CONTACT_SINGLE_REQUEST, payload };
}

export function contactSingleSuccess(payload, params) {
  return { type: CONTACT_SINGLE_SUCCESS, payload, params };
}

export function contactSingleFailure(payload) {
  return { type: CONTACT_SINGLE_FAILURE, payload };
}
